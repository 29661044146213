import React from 'react';
import MenuCreate from './MenuCreate';
import styled from 'styled-components';
import { Link, Switch } from 'react-router-dom';
import { Button, Container, Card, Spinner } from 'react-bootstrap';
import { compose } from 'recompose';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGripLines, faPlus } from '@fortawesome/free-solid-svg-icons';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

import { AddNewSection, ProtectedRoute } from '../common/components';
import { withAuth, withFirebase } from '../common/providers';
import { MenuNew } from '../common/images';

const CardBody = styled(Card.Body)`
  display: flex;
  align-items: center;
`;

const MenuList = ({
  firebase,
  match,
  user,
}) => {
  const [loading, setLoading] = React.useState(true);
  const [dayPart, setDayPart] = React.useState();
  const { storeId } = user;

  const handleDragEnd = (result) => {
    const { destination: dest, source } = result;

    // Didn't go anywhere
    if (!dest) {
      return;
    }

    // Dropped in same place
    if (source.droppableId === dest.droppableId &&
        source.index === dest.index
    ) {
      return;
    }

    const menuOrder = dayPart.menuOrder.slice();
    const menuId = menuOrder[source.index];

    menuOrder.splice(source.index, 1);
    menuOrder.splice(dest.index, 0, menuId);

    const newDayPart = {
      ...dayPart,
      menuOrder,
    };

    setDayPart(newDayPart);

    firebase.updateDayPart(storeId, 'default', newDayPart);
  };

  React.useEffect(() => {
    const fetchMenus = async () => {
      try {
        const apiDayPart = await firebase.getDayPart(storeId, 'default');
        setDayPart(apiDayPart);
      } catch (err) {
        firebase.logError(err);
      }

      setLoading(false);
    };

    fetchMenus();
  }, [firebase, storeId]);

  return (
    <>
      <Container className="content-body">
        <h1 className="mb-4">Menus</h1>

        {loading &&
          <div className="text-center">
            <Spinner animation="border" variant="primary" />
          </div>
        }

        {!loading &&
          <>

            {!!dayPart.menuOrder.length &&
              <AddNewSection to={`${match.url}/create`} className="mb-3 py-4">
                <FontAwesomeIcon icon={faPlus} className="mr-2" /> New Menu
              </AddNewSection>
            }

            {!dayPart.menuOrder.length &&
              <div className="text-center">
                <img className="img-fluid" src={MenuNew} alt="Create a menu" style={{ width: '450px', padding: '5rem 2rem 0' }} />

                <p className="my-3 text-muted">You don't have any menus</p>

                <Button as={Link} size="sm" to={`${match.url}/create`}>+ New Menu</Button>
              </div>
            }

            <DragDropContext onDragEnd={handleDragEnd}>
              <Droppable droppableId="menus">

                {(provided, snapshot) =>

                  <div
                    className={snapshot.isDraggingOver ? 'mm-draggable-section': ''}
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                  >

                    {dayPart.menuOrder.map((menuId, index) => {
                      const menu = dayPart.menus[menuId];

                      return (
                        <Draggable key={menuId} draggableId={menuId} index={index}>
                          {(provided, snapshot) =>

                            <Card
                              className={`mb-3 shadow-sm mm-draggable-item ${snapshot.isDragging ? 'mm-drag-item' : ''}`}
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            >
                              <CardBody>
                                <FontAwesomeIcon icon={faGripLines} />
                                <Link className="ml-3 text-dark" to={`${match.url}/edit/${menuId}`}>
                                  {menu.name}
                                </Link>
                              </CardBody>
                            </Card>

                          }
                        </Draggable>
                      );
                    })}

                    {provided.placeholder}

                  </div>
                }

              </Droppable>
            </DragDropContext>

          </>
        }
      </Container>

      <Switch>
        <ProtectedRoute path={`${match.url}/create`} component={MenuCreate} />
      </Switch>

    </>
  );
};

export default compose(
  withAuth,
  withFirebase,
)(MenuList);