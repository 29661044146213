export const initialState = {
  loading: true,
  working: false,

  menu: {},

  menuEdit: {
    editing: false,
  },

  deletePrompt: {
    show: false,
    title: '',
    content: null,
    handler: null,
    args: {},
  },

  // Keys from slugified name to catch duplicates
  categoryKeys: {},

  categoryEdit: {
    id: null,
    index: -1,
    category: {},
    editing: false,
  },

  // Keys from slugified name to catch duplicates
  productKeys: {},

  productEdit: {
    id: null,
    index: -1,
    catId: null,
    product: {},
  },

  products: [],

  showSteps: true,
};