import React from 'react';
import PropTypes from 'prop-types';
import { Button, Col, Row } from 'react-bootstrap';

const PublicLinkSection = ({
  url,
  onShowQr,
}) => {
  return (
    <section className="section">
      <Row>
        <Col lg={4} className="section__title">
          <h5>Public Link</h5>
        </Col>

        <Col lg={6} className="section__desc">
          <a href={url} className="text-muted">{url}</a>
        </Col>

        <Col lg={2} className="section__edit">
          <Button variant="outline-primary" size="sm" onClick={() => onShowQr()}>
            Show QR
          </Button>
        </Col>
      </Row>
      
    </section>
  );
};

PublicLinkSection.propTypes = {
  url: PropTypes.string.isRequired,
  onShowQr: PropTypes.func.isRequired,
};

export default PublicLinkSection;