import { kebabCase } from 'lodash';

/**
 * Deep clone an object.
 * @param {object} obj Object to be cloned.
 */
export const deepClone = (obj) => JSON.parse(JSON.stringify(obj));

/**
 * Create keys from the name property of each from catalog.
 * @param {object} catalog Catalog object.
 * @example
 * 
 * //from:
 * {
 *   id1: { name: 'Burger' },
 *   id2: { name: 'Ice Cream' },
 * }
 * 
 * // to:
 * {
 *   burger: 'Burger',
 *   'ice-cream': 'Ice Cream',
 * } 
 */
export function createNameKeys(catalog) {
  return Object.keys(catalog)
    .map(id => catalog[id].name)
    .reduce((dict, name) => {
      if (!name) {
        return dict;
      }

      const slug = getSlug(name);
      return {
        ...dict,
        [slug]: name,
      };
    }, {});
}

/**
 * Get slug for a string.
 * @param {string} name String to slugify.
 */
export function getSlug(name) {
  return kebabCase(name);
}