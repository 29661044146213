import React from 'react';
import * as Yup from 'yup';
import { Button, Form, Modal } from 'react-bootstrap';
import { compose } from 'recompose';
import { Formik, Form as FormikForm } from 'formik';

import { withAuth, withFirebase } from '../common/providers';
import { Toast } from '../common/components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBookOpen, faCircle } from '@fortawesome/free-solid-svg-icons';

const MenuSchema = Yup.object().shape({
  name: Yup.string().required('Menu name is required'),
});

const MenuCreate = ({
  firebase,
  history,
  user,
}) => {
  const [show, setShow] = React.useState(true);
  const { storeId } = user;
  const nameRef = React.useRef();

  const handleSubmit = async (values, { setSubmitting }) => {

    const newMenu = {
      ...values,
      active: true,
      categories: {},
      products: {},
      categoryOrder: [],
    };

    try {
      const menu = await firebase.createMenu(storeId, newMenu);

      Toast({ success: 'Menu created!' });

      firebase.logEvent('menu_created', {
        menu_id: menu.id,
        menu_name: menu.name,
      });

      history.push(`/account/menus/edit/${menu.id}`);
    } catch (err) {
      firebase.logError(err);

      Toast({ error: err.message });

      setSubmitting(false);
    }
  };

  const gotoMenuList = () => history.push(`/account/menus`);

  React.useEffect(() => nameRef.current.focus(), []);

  return (
    <Modal
      show={show}
      onHide={() => setShow(false)}
      onExited={gotoMenuList}
      className="mm-success-top"
    >
      <Formik
        initialValues={{ name: '' }}
        validationSchema={MenuSchema}
        onSubmit={handleSubmit}
      >
        {({ errors, handleChange, isSubmitting, isValid, values }) =>

          <FormikForm>
            <Modal.Header closeButton>
              <div className="d-flex justify-content-start align-items-center">
                <span className="fa-stack fa-2x text-success">
                  <FontAwesomeIcon icon={faCircle} className="fa-stack-2x" />
                  <FontAwesomeIcon icon={faBookOpen} inverse className="fa-stack-1x" />
                </span>

                <div>
                  <Modal.Title>New Menu</Modal.Title>
                  <span>Create a new menu</span>
                </div>
              </div>
            </Modal.Header>

            <Modal.Body>


              <Form.Group>
                <Form.Label>Name</Form.Label>
                <Form.Control
                  name="name"
                  ref={nameRef}
                  autoFocus={true}
                  isInvalid={!!errors.name}
                  value={values.name}
                  onChange={handleChange}
                />
                <Form.Control.Feedback type="invalid">{errors.name}</Form.Control.Feedback>
              </Form.Group>

            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="light"
                onClick={() => setShow(false)}
              >
                Cancel
              </Button>

              <Button
                type="submit"
                disabled={!isValid || isSubmitting}
              >
                Create
              </Button>

            </Modal.Footer>


          </FormikForm>
        }
      </Formik>
    </Modal>
  );
};

export default compose(
  withAuth,
  withFirebase,
)(MenuCreate);