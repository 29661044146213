import React from 'react';
import { BrowserRouter as Router, Redirect, Switch, Route } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import { withAuth } from './common/providers';
import { AdminRoute, AnonOnlyRoute, LoadingModal, MainBody, ProtectedRoute } from './common/components';
import { ChangePassword, PasswordReset, Setup, SignIn, SignUp, Verify } from './account';
import { MenuEdit, MenuList } from './menus';
import { Error404 } from './error';
import { ProductList } from './products';
import { StoreEdit, StoreDetailPublic } from './stores';
import { InvitationEdit, InvitationList } from './invitations';

import 'animate.css/animate.min.css';
import 'react-toastify/dist/ReactToastify.css';
import './App.scss';

const App = ({
  isUserLoading,
  user,
}) => {

  if (isUserLoading) {
    return <LoadingModal />
  }

  return (
    <Router>

      <ToastContainer
        autoClose={2500}
        closeButton={false}
        hideProgressBar={true}
        position="bottom-left"
        bodyClassName="mm-toast"
      />

      <MainBody showMainNav={!!user} hideMainNavPaths={[ /stores/, /\/account\/verify/, /error/ ]}>
        <Switch>
          <ProtectedRoute exact path="/" component={({ location }) => <Redirect to={{ ...location, pathname: '/account/menus' }} />} />
          <ProtectedRoute exact path="/account" component={StoreEdit} verifyEmail={false} />
          <AnonOnlyRoute path="/account/signin" component={SignIn} />
          <AnonOnlyRoute path="/account/signup" component={SignUp} />
          <AnonOnlyRoute path="/account/changepassword" component={ChangePassword} />
          <AnonOnlyRoute path="/account/passwordreset" component={PasswordReset} />
          <Route path="/account/verify" component={Verify} />
          <ProtectedRoute path="/account/setup" component={Setup} verifyEmail={false} />
          <ProtectedRoute exact path="/account/menus/edit/:menuId" component={MenuEdit} />
          <ProtectedRoute path="/account/menus" component={MenuList} />
          <ProtectedRoute path="/account/products" component={ProductList} />
          <AdminRoute exact path="/admin/invitations" component={InvitationList} />
          <AdminRoute path="/admin/invitations/edit/:invitationId" component={InvitationEdit} />
          <Route path="/stores/:storeId" component={StoreDetailPublic} />
          <Route path="/error" component={Error404} />
          <Redirect path="*" to="/error" />
        </Switch>
      </MainBody>

    </Router>
  );
}

export default withAuth(App);