import React from 'react';
import { createNameKeys } from '../helpers';

export const productAdd = (state, action) => {
  const { catId } = action;

  return {
    ...state,
    productEdit: {
      ...state.productEdit,
      catId,
      product: null,
      editing: true,
    },
  };
}

export const productDelete = (state, action) => {
  const { prodId, catId, prodIndex, handler } = action;
  const { menu } = state;
  const product = menu.products[prodId];

  return {
    ...state,
    deletePrompt: {
      handler,
      args: { prodId, catId, prodIndex },
      show: true,
      title: 'Delete Product',
      content: (
        <p>Are you sure you want to delete <strong>{product.name}</strong> from the menu?</p>
      ),
    }
  };
};

export const productEdit = (state, action) => {
  const { id, catId, product, prodIndex: index } = action;

  return {
    ...state,
    productEdit: {
      ...state.productEdit,
      id,
      index,
      catId,
      product,
      editing: true,
    },
  };
};

export const productEditCancel = (state) => ({
  ...state,
  productEdit: {
    ...state.productEdit,
    id: null,
    index: -1,
    catId: null,
    product: {},
    editing: false,
  },
});

export const productUpdate = (state, action) => {
  const { id: newId, catId, product } = action;
  const { menu, productEdit } = state;
  const { id: oldId } = productEdit;

  const category = menu.categories[catId];
  const productOrder = category.productOrder.slice();

  // Add the new product
  if (!oldId) {
    productOrder.push(newId);
  }

  const categories = {
    ...menu.categories,
    [catId]: {
      ...menu.categories[catId],
      productOrder,
    },
  };

  const products = {
    ...menu.products,
    [newId]: {
      catId,
      name: product.name,
      description: product.description,
      price: product.price,
    },
  };

  const productKeys = createNameKeys(products);

  return {
    ...state,
    productKeys,
    productEdit: {
      ...state.productEdit,
      id: null,
      index: -1,
      catId: null,
      product: null,
      editing: false,
    },
    menu: {
      ...state.menu,
      categories,
      products,
    },
  };
};

export const productListLoad = (state, action) => {
  const { products } = action;

  return {
    ...state,
    products,
  };
};