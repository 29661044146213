import React from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from 'react-bootstrap';

const InvitationDeleteModal = ({
  show,
  deleteStore,
  onConfirm,
  onConfirmAll,
  onCancel,
}) => {
  return (
    <Modal show={show} size="lg" centered={true} onHide={() => onCancel()}>
      <Modal.Body className="p-4">
        <h3>Delete Invitation</h3>
        <p>Are you sure you want to permanently delete this invitation?</p>

        <div className="text-right">
          <Button
            variant="light"
            onClick={() => onCancel()}
          >
            Cancel
          </Button>

          {deleteStore &&
            <Button
              className="ml-2"
              variant="outline-danger"
              onClick={() => onConfirmAll()}
            >
              Delete Invitation + Store
            </Button>
          }

          <Button
            className="ml-2"
            variant="danger"
            onClick={() => onConfirm()}
          >
            Delete Invitation
          </Button>
        </div>

      </Modal.Body>
    </Modal>
  );
};

InvitationDeleteModal.propTypes = {
  show: PropTypes.bool.isRequired,
  deleteStore: PropTypes.bool.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onConfirmAll: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default InvitationDeleteModal;