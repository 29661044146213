import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { compose } from 'recompose';
import { withAuth, withFirebase } from '../providers';

const ProtectedRoute = ({
  firebase,
  location,
  user,
  ...props
}) => {
  if (user && ~user.roles.indexOf('superadmin')) {
    return <Route {...props} />;
  }

  return <Redirect to={{ ...location, pathname: "/account/signin" }} />;
};

export default compose(
  withAuth,
  withFirebase,
)(ProtectedRoute);