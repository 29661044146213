import React from 'react';
import styled from 'styled-components';
import { NotFound } from '../common/images';

const PageContainer = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Container = styled.div`
  text-align: center;
`;

const Image = styled.img`
  width: 60%;
`;

const Error404 = () => (
  <PageContainer>
    <Container>
      <Image src={NotFound} alt="404" />
      <h3>Not Found</h3>
    </Container>
  </PageContainer>
);

export default Error404;