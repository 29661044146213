import React from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { Col, Button, Form, Row, Collapse, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Formik, Form as FormikForm } from 'formik';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { ConfirmCancel } from '../../common/components';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';

const StoreNameSchema = Yup.object().shape({
  name: Yup.string().required('Store name is required'),
});

const NameVerification = ({
  store,
  storeId,
  editing,
  working,
  onEditing,
  onConfirm,
}) => {
  const [showWarning, setShowWarning] = React.useState(false);
  const [curStore, setCurStore] = React.useState(store);

  const doConfirm = (values) => onConfirm(values);

  const handleSubmit = async (values) => {
    if (store.verified) {
      // If verified, warning user that they will lose verification status
      setCurStore({ ...values, verified: false });
      setShowWarning(true);
    } else {
      doConfirm(values);
    }
  };

  return (
    <section className="section">

      <ConfirmCancel
        variant="warning"
        title="Verification Loss"
        content={
          <>
            <p>Are you sure you want to change the store name?</p>
            <p>Updating the store name will:</p>
            <ul>
              <li>Invalidate store verification</li>
            </ul>
            <p>You can resubmit if the new name needs to be verified.</p>
          </>
        }
        icon={faExclamationTriangle}
        show={!working && showWarning}
        onCancel={() => setShowWarning(false)}
        onConfirm={() => doConfirm(curStore)}
      />

      <Row>
        <Col lg={4} className="section__title">
          <h5>Store Name & Verification</h5>
        </Col>

        {!editing &&
          <>
            <Col lg={6} className="section__desc">
              {store.name}
              {store.verified && 
                <OverlayTrigger
                  placement="right"
                  overlay={<Tooltip>Verified</Tooltip>}
                >
                  <FontAwesomeIcon icon={faCheckCircle} className="text-primary ml-2" />
                </OverlayTrigger>
              }
            </Col>

            <Col lg={2} className="section__desc">
              <Button variant="outline-primary" size="sm" onClick={() => onEditing()}>
                Edit
              </Button>
            </Col>
          </>
        }

      </Row>

      <Collapse in={editing}>
        <div>

          <Formik
            initialValues={{ ...store }}
            validationSchema={StoreNameSchema}
            onSubmit={handleSubmit}
          >
            {({ errors, handleChange, isValid, values }) =>
              <FormikForm>

                <Form.Group as={Row}>
                  <Form.Label column lg={4}>Store Name</Form.Label>
                  <Col lg={8}>
                    <Form.Control
                      name="name"
                      isInvalid={!!errors.name}
                      value={values.name}
                      onChange={handleChange}
                    />
                  </Col>
                </Form.Group>

                <Row>
                  <Col lg={{ span: 8, offset: 4 }}>

                    {store.verified &&
                      <p className="mb-3">
                        <FontAwesomeIcon icon={faCheckCircle} className="text-success" /> Your store is verified.
                      </p>
                    }

                    {!store.verified &&
                      <p className="text-muted">
                        <small>
                          Earn trust by showing a verification badge next to your store name. In order to verify, email&nbsp;
                          <a
                            target="_blank"
                            href={`mailto:support@markermenu.com?subject=Account Verification: ${storeId}`} rel="noopener noreferrer"
                          >
                            support@markermenu.com
                          </a>&nbsp;
                          with your business license. The business name on the business license <em>must</em> match the name&nbsp;
                          you entered as your store name. This may take 2-3 business days.
                        </small>
                      </p>
                    }

                    <Button type="submit" disabled={!isValid}>Save</Button>
                  </Col>
                </Row>

              </FormikForm>
            }
          </Formik>

        </div>
      </Collapse>

    </section>
  );
};

NameVerification.propTypes = {
  store: PropTypes.object.isRequired,
  storeId: PropTypes.string.isRequired,
  editing: PropTypes.bool.isRequired,
  working: PropTypes.bool.isRequired,
  onEditing: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};

export default NameVerification;