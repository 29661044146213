import React from 'react';
import * as Yup from 'yup';
import { Button, Form, Spinner } from 'react-bootstrap';
import { Formik, Form as FormikForm } from 'formik';
import { Toast, LogoCardContainer } from '../common/components';
import { withFirebase } from '../common/providers';
import { Link } from 'react-router-dom';
import { ERROR_GENERIC, FIREBASE_ERRORS } from '../common/Constants';

const PasswordResetSchema = Yup.object().shape({
  email: Yup.string()
    .required('Email is required')
    .email('Invalid email'),
});

const PasswordReset = ({ firebase }) => {

  const handleSubmit = async (values) => {
    try {
      await firebase.sendPasswordResetEmail(values.email);
      Toast({ success: 'Email sent!' });
    } catch (err) {
      firebase.logError(err);

      const message = FIREBASE_ERRORS[err.code] || ERROR_GENERIC;

      Toast({ error: message })
    }
  };

  return (
    <LogoCardContainer
      title="Forgot Password"
      description="That's okay. Fill out your email and we'll reset it for you."
    >
      <Formik
        initialValues={{ email: '' }}
        validationSchema={PasswordResetSchema}
        onSubmit={handleSubmit}
      >
        {({ errors, handleChange, isSubmitting, isValid, values }) =>

          <FormikForm>

            <Form.Group>
              <Form.Label>Email</Form.Label>
              <Form.Control
                name="email"
                autoFocus={true}
                isInvalid={!!errors.email}
                value={values.email}
                onChange={handleChange}
              />
              <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
            </Form.Group>

            <p>
              <Button
                block
                className="d-flex justify-content-center align-items-center"
                type="submit"
                disabled={!isValid || isSubmitting}
              >
                Reset Password
                {isSubmitting && <Spinner className="ml-2" animation="border" size="sm" />}
              </Button>
            </p>

          </FormikForm>

        }
      </Formik>

      <div className="text-center mb-4">
        I remember my password. <Link to="/account/signin">Sign In</Link>
      </div>

    </LogoCardContainer>
  );
};

export default withFirebase(PasswordReset);