import React from 'react';
import styled from 'styled-components';
import Loading from './Loading';
import { Modal } from 'react-overlays';

const Backdrop = styled.div`
  position: fixed;
  z-index: 1040;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #000;
  opacity: 0.7;
`;

const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1041;
`;

const LoadingApp = ({ show = true }) => (
  <Modal show={show} renderBackdrop={() => <Backdrop />}>
    <Container className="d-flex justify-content-center align-items-center">
      <Loading />
    </Container>
  </Modal>
);

export default LoadingApp;