import React from 'react';
import styled from 'styled-components';
import semverParse from 'semver/functions/parse';
import { Nav, Navbar } from 'react-bootstrap';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';

import { withFirebase } from '../providers';
import { Logo } from '../images';
import info from '../../../package.json';

import './MainNav.scss';

const LogoImg = styled.img`
  width: 50px;
`;

const MainNav = ({
  location,
  firebase,
}) => {

  const getSemVer = () => {
    const semver = semverParse(info.version);
    return `${semver.major}.${semver.minor}`;
  }

  const handleSignOut = () => firebase.signOut();

  return (
    <Navbar bg="white" variant="light" expand="md" className="fixed-left">
      <Navbar.Brand as={Link} to="/" className="d-flex justify-content-start align-items-center">
        <span>
          <LogoImg src={Logo} />
        </span>
        <span className="ml-2 d-none d-md-inline d-lg-inline d-xl-inline">
          <h5 className="title">Marker</h5>
          <h5 className="title">Menu</h5>
        </span>
      </Navbar.Brand>

      <Navbar.Toggle aria-controls="mm-navbar-nav" className="border-0" />
      <Navbar.Collapse id="mm-navbar-nav">
        <Nav className="mr-auto">

          <Nav.Link
            as={Link}
            to="/account/menus"
            active={location.pathname.match(/^\/account\/menus/)}
          >
            Menus
          </Nav.Link>

          <Nav.Link
            as={Link}
            to="/account/products"
            active={location.pathname.match(/^\/account\/products/)}
          >
            Products
          </Nav.Link>

          <hr />
        </Nav>

        <Nav className="ml-auto">
          <Nav.Link
            as={Link}
            to="/account"
            active={location.pathname.match(/^\/account$/)}
          >
            Account
          </Nav.Link>

          <Nav.Link id="signout" onClick={handleSignOut}>
            Sign Out
          </Nav.Link>

          <div id="version" className="nav-link" title={info.version}>
            v{getSemVer()}
          </div>
        </Nav>
      </Navbar.Collapse>

    </Navbar>
  );
};

export default compose(
  withFirebase,
  withRouter,
)(MainNav);