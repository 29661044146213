import React from 'react';
import { v4 as uuid } from 'uuid';
import { extname } from 'path';
import { compose } from 'recompose';
import { Badge, Button, Form, InputGroup, Modal, Nav, Spinner } from 'react-bootstrap';
import { Formik, Form as FormikForm } from 'formik';

import { withAuth, withFirebase } from '../common/providers';
import { ImageUpload, Toast } from '../common/components';
import { ERROR_GENERIC } from '../common/Constants';
import { faBox, faCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import ProductSchema from './schemas/ProductSchema';

const ProductCreate = ({
  firebase,
  user,
  history,
}) => {
  const [show, setShow] = React.useState(true);
  const [addedProduct, setAddedProduct] = React.useState();
  const [imageLoading, setImageLoading] = React.useState(false);
  const { storeId } = user;
  const nameRef = React.useRef();

  const gotoProductList = () => history.push('/account/products', { product: addedProduct });

  const handleAddProduct = async (values) => {
    try {
      const existingProduct = await firebase.getProductByName(storeId, values.name);
      if (existingProduct) {
        Toast({ error: 'Duplicate product!' });
        return;
      }

      const newProduct = {
        ...values,
        key: values.name.toLowerCase(),
      };

      const product = await firebase.createProduct(storeId, newProduct);

      Toast({ success: `${values.name} added!` });

      firebase.logEvent('product_created', {
        prod_id: product.id,
        prod_name: product.name,
      });

      setAddedProduct(product);
      setShow(false);
    } catch (err) {
      firebase.logError(err);
      Toast({ error: ERROR_GENERIC });
    }
  };

  const handleImageDelete = (clearImageRefs) => clearImageRefs();

  const handleImageSelect = async ({ files, setImagePath, setImageUrl }) => {
    const file = files[0];

    if (file) {
      setImageLoading(true);

      const name = uuid();
      const ext = extname(file.name);
      const imagePath = `/stores/${storeId}/products/${name}${ext}`;

      // Upload file
      const uploadTask = firebase
        .getImageRef(imagePath)
        .put(file);

      uploadTask.on('state_changed',
        (snapshot) => { /* Snapshot for progress */ },
        (err) => Toast({ error: err.message }),
        () => {
          uploadTask.snapshot.ref.getDownloadURL()
            .then(url => {
              setImageUrl(url);
              setImagePath(imagePath);
              setImageLoading(false);
            });
        },
      );
    }
  };

  React.useEffect(() => nameRef.current.focus(), []);

  return (
    <Modal
      show={show}
      className="mm-success-top"
      onHide={() => setShow(false)}
      onExited={gotoProductList}
    >
      <Formik
        initialValues={{ name: '', description: '', price: 0, imagePath: '', imageUrl: '', active: true }}
        validationSchema={ProductSchema}
        onSubmit={handleAddProduct}
      >
        {({ errors, handleBlur, handleChange, isSubmitting, isValid, setFieldValue, values }) =>

          <FormikForm>
            <Modal.Header closeButton>
              <div className="d-flex justify-content-start align-items-center">
                <span className="fa-stack fa-2x text-success">
                  <FontAwesomeIcon icon={faCircle} className="fa-stack-2x" />
                  <FontAwesomeIcon icon={faBox} inverse className="fa-stack-1x" />
                </span>

                <div>
                  <Modal.Title>New Product</Modal.Title>
                  <span>Enter new product information</span>
                </div>
              </div>
            </Modal.Header>

            <Modal.Body className="px-0">
              <Nav activeKey="detail" className="mm-nav mx-4">
                <Nav.Item>
                  <Nav.Link eventKey="detail" active>Detail</Nav.Link>
                </Nav.Item>
              </Nav>
              <hr className="mt-0 pt-0" />

              <div className="mx-4">
                <Form.Group>
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    name="name"
                    ref={nameRef}
                    autoFocus={true}
                    isInvalid={!!errors.name}
                    value={values.name}
                    onChange={handleChange}
                  />
                  <Form.Control.Feedback type="invalid">{errors.name}</Form.Control.Feedback>
                </Form.Group>

                <Form.Group>
                  <Form.Label>Desciption</Form.Label>
                  <Form.Control
                    name="description"
                    as="textarea"
                    isInvalid={!!errors.description}
                    value={values.description}
                    onChange={handleChange}
                  />
                  <Form.Control.Feedback type="invalid">{errors.description}</Form.Control.Feedback>
                </Form.Group>

                <Form.Group>
                  <Form.Label>
                    Price {!values.price && <Badge variant="warning">Free</Badge>}
                  </Form.Label>

                  <InputGroup>
                    <InputGroup.Prepend>
                      <InputGroup.Text>$</InputGroup.Text>
                    </InputGroup.Prepend>
                    <Form.Control
                      name="price"
                      type="number"
                      step="0.01"
                      isInvalid={!!errors.price}
                      value={values.price}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                  </InputGroup>
                  <Form.Control.Feedback type="invalid">{errors.price}</Form.Control.Feedback>
                </Form.Group>

                <div>
                  <ImageUpload
                    imageUrl={values.imageUrl}
                    working={imageLoading}
                    onAccepted={files => handleImageSelect({
                      files,
                      setImagePath: (imagePath) => setFieldValue('imagePath', imagePath),
                      setImageUrl: (imageUrl) => setFieldValue('imageUrl', imageUrl),
                    })}
                    onDeleted={() => handleImageDelete(
                      () => {
                        setFieldValue('imagePath', null);
                        setFieldValue('imageUrl', null);
                      }
                    )}
                  />
                </div>

              </div>

            </Modal.Body>

            <Modal.Footer>
              <Button
                variant="light"
                onClick={() => setShow(false)}
              >
                Cancel
              </Button>

              <Button
                className="d-flex align-items-center"
                variant="primary"
                type="submit"
                disabled={!isValid || isSubmitting}
              >
                Save {isSubmitting && <Spinner animation="border" size="sm" className="ml-2" />}
              </Button>
            </Modal.Footer>

          </FormikForm>
        }
      </Formik>
    </Modal>
  );
};

export default compose(
  withAuth,
  withFirebase,
)(ProductCreate);