import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Card } from 'react-bootstrap';

import LogoText from './LogoText';

const CardStyled = styled(Card)`
  width: 500px;
`;

const LogoCardContainer = ({
  children,
  title,
  description,
}) => (
  <div className="py-4 d-flex justify-content-center align-items-center">

    <CardStyled className="shadow-sm">
      <Card.Body className="p-4">

        <div className="text-center py-4">
          <LogoText />
        </div>

        <div className="text-center px-4">
          <h3>{title}</h3>
          {description && <p className="text-muted">{description}</p>}
        </div>

        <div className="px-4 py-2">
          {children}
        </div>

      </Card.Body>
    </CardStyled>
  </div>
);

LogoCardContainer.propTypes = {
  children: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
};

export default LogoCardContainer;