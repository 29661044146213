import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Button, Fade, Spinner } from 'react-bootstrap';
import './ModalOverlay.scss';

const Overlay = styled.div``;
const OverlayBody = styled.div``;
const Content = styled.div``;
const Title = styled.h1``;
const Description = styled.p``;
const Buttons = styled.p``;
const ButtonConfirm = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ModalOverlay = ({
  show,
  title,
  description,
  variant = 'danger',
  working,
  onConfirm,
  onCancel,
}) => (
  <Fade in={show}>
    <Overlay className={`mm-overlay mm-overlay--${show ? 'show' : 'hide'}`}>
      <OverlayBody className="mm-overlay__body">

        <Content className="mm-content">
          <Title className={`mm-content__title mm-content__title--${variant}`}>
            {title}
          </Title>
          <Description className={`mm-content__desc mm-content__desc--${variant}`}>
            {description}
          </Description>

          <Buttons className="p-2">
            <ButtonConfirm
              block
              variant={`outline-${variant}`}
              disabled={working}
              onClick={() => onConfirm()}
            >
              Yes, I'm sure! {working && <Spinner animation="border" size="sm" className="ml-2" />}
            </ButtonConfirm>

            <Button
              block
              variant="outline-light"
              className="mr-2 border-0"
              disabled={working}
              onClick={() => onCancel()}
            >
              No
            </Button>
          </Buttons>

        </Content>

      </OverlayBody>
    </Overlay>

  </Fade>
);

ModalOverlay.propTypes = {
  show: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]).isRequired,
  working: PropTypes.bool.isRequired,
  variant: PropTypes.string,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default ModalOverlay;