import React from 'react';
import styled from 'styled-components';
import { Button, Col, Container, OverlayTrigger, Row, Spinner, Tooltip } from 'react-bootstrap';
import { Link, Redirect } from 'react-router-dom';
import { compose } from 'recompose';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';

import MenuSettings from './MenuSettings';
import Category from './Category';
import CategoryEdit from './CategoryEdit';
import ProductAdd from './ProductAdd';
import ProductEdit from '../products/ProductEdit';
import * as MenuHelpers from './MenuHelpers';
import { deepClone } from '../common/helpers';
import { ERROR_GENERIC } from '../common/Constants';
import { AddNewSection, Toast, ConfirmCancel } from '../common/components';
import { withAuth, withFirebase } from '../common/providers';
import { reducer, actions, initialState } from './reducers/MenuEdit';
import { MenuAdd } from '../common/images';

const StepsRow = styled(Row)`
  @media (min-width: 992px) {
    padding: 2rem 4rem;
  }
`;

const MenuTitle = styled.h1`
  margin-bottom: 0;

  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
`;

const MenuEdit = ({
  firebase,
  history,
  match,
  user,
}) => {
  const [state, dispatch] = React.useReducer(reducer, initialState);
  const {
    loading,
    working,
    deletePrompt,
    menuEdit,
    categoryEdit,
    productEdit,
    menu,
    products,
    showSteps,
  } = state;

  const { storeId } = user;
  const { menuId } = match.params;

  const doMenuUpdate = async (newMenu) => {
    try {
      await firebase.updateStoreMenu(storeId, menuId, 'default', newMenu);
      dispatch(actions.menuUpdate(newMenu));
    } catch (err) {
      firebase.logError(err);
      Toast({ error: ERROR_GENERIC });
    }
  };

  const handleCategoryAddOrUpdate = async (catId, category) => {
    dispatch(actions.toggleWorking());

    const curMenu = deepClone(menu);
    const newMenu = MenuHelpers.categoryAddOrUpdate(curMenu, catId, category);

    await doMenuUpdate(newMenu);
  };

  const handleCategoryDelete = async ({ catId, index }) => {
    dispatch(actions.toggleWorking());
    const newMenu = MenuHelpers.categoryDelete(deepClone(menu), catId, index);
    await doMenuUpdate(newMenu);
  };

  const handleMenuDelete = async () => {
    dispatch(actions.toggleWorking());

    try {
      await firebase.deleteStoreMenu(storeId, menuId);

      Toast({
        icon: faTrash,
        error: 'Menu deleted!',
      });

      firebase.logEvent('menu_deleted', {
        menu_id: menuId,
        daypart_id: 'default',
      });

      history.push('/account/menus');
    } catch (err) {
      firebase.logError(err);
      Toast({ error: err.message });
      dispatch(actions.toggleWorking());
    }
  };

  const handleDragEnd = async (result) => {
    const { destination: dest, source } = result;

    // Didn't go anywhere
    if (!dest) {
      return;
    }

    // Dropped in same place
    if (
      source.droppableId === dest.droppableId &&
      source.index === dest.index
    ) {
      return;
    }

    let updatedMenu = { ...menu };
    const { sourceIds, destIds } = result.type === 'cat'
      ? {
        sourceIds: updatedMenu.categoryOrder,
        destIds: updatedMenu.categoryOrder,
      } : {
        sourceIds: updatedMenu.categories[source.droppableId].productOrder,
        destIds: updatedMenu.categories[dest.droppableId].productOrder,
      };

    // cat: Take cat item out of same list and put in new position
    // prod: Take prod out of the source list and put in new/current
    //       list in the new position
    const id = sourceIds[source.index];
    sourceIds.splice(source.index, 1);
    destIds.splice(dest.index, 0, id);

    switch (result.type) {
      case 'cat':
        updatedMenu = {
          ...menu,
          categoryOrder: destIds,
        };

        break;
      
      default:
        const prod = updatedMenu.products[id];
        const sourceCatId = source.droppableId;
        const destCatId = dest.droppableId;

        updatedMenu = {
          ...menu,
          categories: {
            ...menu.categories,
            // Update product order on categories
            [sourceCatId]: {
              ...menu.categories[sourceCatId],
              productOrder: sourceIds,
            },
            [destCatId]: {
              ...menu.categories[destCatId],
              productOrder: destIds,
            },
          },
          products: {
            ...menu.products,
            // Update prod cat assignment
            [id]: {
              ...prod,
              catId: destCatId,
            },
          },
        };

        break;
    }

    await doMenuUpdate(updatedMenu);
  };

  const handleProductAddNew = async (catId, newProduct) => {
    try {
      dispatch(actions.toggleWorking());

      const addedProduct = await firebase.createProduct(storeId, newProduct);
      const curMenu = deepClone(menu);
      const newMenu = MenuHelpers.productAddOrUpdate(curMenu, catId, addedProduct.id, addedProduct);

      await doMenuUpdate(newMenu);

      // Update all products for menu edit
      const newProducts = [
        ...products,
        addedProduct,
      ];
      dispatch(actions.productListLoad(newProducts));
    } catch (err) {
      Toast({ error: ERROR_GENERIC });
    }
  };

  const handleProductAddOrUpdate = async (catId, prodId, product) => {
    dispatch(actions.toggleWorking());

    const curMenu = deepClone(menu);
    const newMenu = MenuHelpers.productAddOrUpdate(curMenu, catId, prodId, product);

    await doMenuUpdate(newMenu);
  };

  const handleProductDelete = async ({ catId, prodId, prodIndex }) => {
    dispatch(actions.toggleWorking());

    const curMenu = deepClone(menu);
    const newMenu = MenuHelpers.productDelete(curMenu, catId, prodId, prodIndex);

    await doMenuUpdate(newMenu);
  };

  React.useEffect(() => {
    const fetchMenu = async () => {
      try {
        const apiMenu = await firebase.getStoreMenu(storeId, menuId);
        dispatch(actions.menuLoad(apiMenu));
      } catch (err) {
        firebase.logError(err);
      }

      dispatch(actions.toggleLoading());
    };

    fetchMenu();
  }, [firebase, menuId, storeId]);

  React.useEffect(() => {
    const fetchProducts = async () => {
      try {
        const apiProducts = await firebase.getProducts(storeId);
        dispatch(actions.productListLoad(apiProducts));
      } catch (err) {
        firebase.logError(err);
      }
    };

    fetchProducts();
  }, [firebase, storeId]);

  if (loading) {
    return (
      <div className="text-center my-4 py-4">
        <Spinner animation="border" variant="primary" />
      </div>
    );
  }

  if (!menu) {
    return <Redirect to="/error" />;
  }

  return (
    <Container className="content-body">
      
      <MenuSettings
        menu={menu}
        show={menuEdit.editing}
        working={working}
        onConfirm={async (menu) => await doMenuUpdate(menu)}
        onCancel={() => dispatch(actions.menuEditCancel())}
        onDelete={handleMenuDelete}
      />

      {categoryEdit.editing && 
        <CategoryEdit
          id={categoryEdit.id}
          existingKeys={state.categoryKeys}
          category={categoryEdit.category}
          show={categoryEdit.editing}
          title={categoryEdit.id ? 'Edit Category ' : 'New Category'}
          working={working}
          onConfirm={handleCategoryAddOrUpdate}
          onCancel={() => dispatch(actions.categoryEditCancel())}
        />
      }

      {productEdit.editing && !productEdit.id &&
        <ProductAdd
          id={productEdit.id}
          catId={productEdit.catId}
          productList={state.products}
          productKeys={state.productKeys}
          show={productEdit.editing}
          working={working}
          onAddNewProduct={handleProductAddNew}
          onConfirm={(id, catId, product) => handleProductAddOrUpdate(catId, id, product)}
          onCancel={() => dispatch(actions.menuUpdate(menu))}
        />
      }

      {productEdit.editing && productEdit.id &&
        <ProductEdit
          id={productEdit.id}
          onConfirm={(id, prod) => dispatch(actions.productUpdate(id, productEdit.catId, prod))}
          onCancel={() => dispatch(actions.productEditCancel())}
          onError={() => dispatch(actions.productEditCancel())}
        />
      }

      <ConfirmCancel
        title={deletePrompt.title}
        prompt={deletePrompt.description}
        content={deletePrompt.content}
        variant="danger"
        icon={faTrash}
        show={deletePrompt.show}
        working={working}
        onConfirm={() => deletePrompt.handler(deletePrompt.args)}
        onCancel={() => dispatch(actions.menuUpdate(menu))}
      />

      <div className="mb-4">
        <div className="d-flex justify-content-start align-items-center">
          <MenuTitle className="mr-2" onClick={() => dispatch(actions.menuEdit(menu))}>
            {menu.name}
          </MenuTitle>

          <OverlayTrigger
            placement="right"
            overlay={<Tooltip>Preview</Tooltip>}
            delay={1000}
          >
            <Link to={`/stores/${storeId}/dayparts/default/menus/${menuId}`} target="_blank" className="text-secondary">
              <FontAwesomeIcon icon={faEye} />
            </Link>
          </OverlayTrigger>
        </div>

      </div>

      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId="menu" type="cat">

          {(provided, snapshot) => (

            <div
              className={snapshot.isDraggingOver ? 'mm-draggable-section': ''}
              ref={provided.innerRef}
              {...provided.droppableProps}
            >

              {menu.categoryOrder.map((catId, index) =>
                <Category
                  key={catId}
                  id={catId}
                  index={index}
                  category={menu.categories[catId]}
                  productData={menu.products}
                  onCategoryDelete={catId => dispatch(actions.categoryDelete(catId, index, handleCategoryDelete))}
                  onCategoryEdit={catId => dispatch(actions.categoryEdit(catId, index, menu.categories[catId]))}
                  onProductCreate={(catId) => dispatch(actions.productAdd(catId))}
                  onProductDelete={(prodId, prodIndex) =>  dispatch(actions.productDelete(catId, prodId, prodIndex, handleProductDelete))}
                  onProductEdit={(prodId, prodIndex, prod) => dispatch(actions.productEdit(prodId, catId, prodIndex, prod))}
                />
              )}

              {provided.placeholder}

            </div>

          )}

        </Droppable>
      </DragDropContext>

      {/* Show illustration on first menu creation */}
      {showSteps &&
        <div className="my-4">
          <div className="text-center pb-4 mb-4">
            <img src={MenuAdd} alt="New Menu" />
          </div>

          <StepsRow className="mb-2">
            <Col lg={4}>
              <h5>1. Create Category</h5>
              <p>Start by creating a category</p>
            </Col>
            <Col lg={4}>
              <h5>2. Add Products</h5>
              <p>Then add products to your category</p>
            </Col>
            <Col lg={4}>
              <h5>3. Repeat</h5>
              <p>Repeat 'til your menu is complete</p>
            </Col>
          </StepsRow>

          <div className="text-center mb-4">
            <Button size="sm" onClick={() => dispatch(actions.categoryCreate())}>
              New Category
            </Button>
          </div>
        </div>
      }

      {/* Hide on initial menu creation, show illustration instea */}
      {!showSteps &&
        <AddNewSection to="#" className="my-3 p-4" onClick={() => dispatch(actions.categoryCreate())}>
          <FontAwesomeIcon icon={faPlus} className="mr-2" /> New Category
        </AddNewSection>
      }

    </Container>
  );
};

export default compose(
  withAuth,
  withFirebase,
)(MenuEdit);