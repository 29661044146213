import React from 'react';
import PropTypes from 'prop-types';
import Numeral from 'numeral';
import styled from 'styled-components';
import { Button, Col, Modal, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

import './Menu.scss';

const MenuName = styled.h3`
  color: ${props => props.theme.color};
`;

const ProductName = styled.h6`
  color: ${props => props.theme.color};
`;

const Price = styled.div`
  color: ${props => props.theme.color};
`;

const DetailTitle = styled.h3`
  color: ${props => props.theme.color};
`;

const DetailPrice = styled.p`
  color: ${props => props.theme.color};
`;

const ButtonClose = styled(Button)`
  color: ${props => props.hasimage === 'true' ? 'var(--white)' : props.theme.color};
  transition: filter 500ms;

  &:hover {
    color: ${props => props.hasimage === 'true' ? 'var(--white)' : props.theme.color};
    filter: brightness(85%);
  }
`;

const Menu = ({ menu }) => {
  const [product, setProduct] = React.useState();

  const renderProducts = (catId) => menu.categories[catId].productOrder.map(prodId => {
    const prod = menu.products[prodId];
    const { name, description, price, imageUrl } = prod;

    return (
      <Col md={6} key={prodId} className="menu__prod" onClick={() => setProduct(prod)}>
        {imageUrl &&
          <div className="menu__prodimg">
            <img src={imageUrl} alt={name} />
          </div>
        }

        <div className="menu__data">
          <ProductName className="menu__prodname">{name}</ProductName>
          <div className="menu__proddesc">{description}</div>

          {!!+price &&
            <Price className="menu__prodprice">{Numeral(price).format('$0,0.00')}</Price>
          }
        </div>
      </Col>
    );
  });

  const renderCategories = () => menu.categoryOrder.map(catId =>
    <React.Fragment key={catId}>
      <div>
        <h5 className="menu__catname">{menu.categories[catId].name}</h5>
        <Row noGutters className="menu__prods">
          {renderProducts(catId)}
        </Row>
      </div>
      
    </React.Fragment>
  );

  return (
    <div className="menu">
      <MenuName className="menu__name">{menu.name}</MenuName>

      <hr className="menu__catdivier" />

      {renderCategories()}

      <Modal
        show={!!product}
        onHide={() => setProduct(null)}
        style={{ zIndex: 5000 }}
      >
        {product &&
          <Modal.Body className="detail p-0">
            {product.imageUrl &&
              <div className="detail__img animate__animated animate__fadeIn">
                <img src={product.imageUrl} alt={product.name} />
              </div>
            }

            <div className="detail__data">
              <DetailTitle className="detail__title">{product.name}</DetailTitle>
              <p className="detail__desc">{product.description}</p>
              <DetailPrice className="detail__price">{Numeral(product.price).format('$0,0.00')}</DetailPrice>
            </div>

            <ButtonClose
              variant="link"
              className="detail__close"
              hasimage={(!!product.imageUrl).toString()}
              onClick={() => setProduct(null)}
            >
              <FontAwesomeIcon icon={faTimes} size="2x" />
            </ButtonClose>

          </Modal.Body>
        }
      </Modal>

    </div>
  );
};

Menu.propTypes = {
  menu: PropTypes.object.isRequired,
};

export default Menu;