import React from 'react';
import { Button, Container, Table } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faEllipsisH } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import { withFirebase } from '../common/providers';
import { PAGE_LIMIT } from '../common/Constants';
import { ConfirmCancel, LoadingModal, Pagination, Toast } from '../common/components';

import InvitationCreateModal from './InvitationCreateModal';
import InvitationDeleteModal from './InvitationDeleteModal';

const InvitationList = ({
  firebase,
  history,
  match,
}) => {
  const [loading, setLoading] = React.useState(true);
  const [working, setWorking] = React.useState(false);
  const [invitations, setInvitations] = React.useState([]);
  const [showCreate, setShowCreate] = React.useState(false);
  const [deleteInvitationIndex, setDeleteInvitationIndex] = React.useState(-1);
  const [curPage, setCurPage] = React.useState(0);

  const visibleInvitations = invitations.slice(curPage * PAGE_LIMIT, (curPage * PAGE_LIMIT) + PAGE_LIMIT);

  const handleCreateInvitation = async (invitation) => {
    setShowCreate(false);
    setWorking(true);

    try {
      const newInvitation = await firebase.createInvitation(invitation);

      Toast({ success: `${invitation.name} created!`});

      history.push(`${match.url}/edit/${newInvitation.id}`);
    } catch (err) {
      Toast({ error: err.message });
      setWorking(false);
    }
  };

  const handleDeleteInvitation = async (deleteStore) => {
    const index = deleteInvitationIndex;

    setDeleteInvitationIndex(-1);
    setWorking(true);

    try {
      const newInvitations = invitations.slice();
      const invitation = newInvitations[index];

      await firebase.deleteInvitation(invitation.id, deleteStore);

      newInvitations.splice(index, 1);

      setInvitations(newInvitations);
    } catch (err) {
      Toast({ error: err.message });
    }

    setWorking(false);
  };

  const handleShowCreate = () => {
    setShowCreate(true);
  }

  React.useEffect(() => {
    const fetchInvitations = async () => {
      try {
        const apiInvitations = await firebase.getInvitations();
        setInvitations(apiInvitations);
        setLoading(false);
      } catch (err) {
        firebase.logError(err);
        Toast({ error: err.message });
      }
    };

    fetchInvitations();
  }, [firebase]);

  if (loading) {
    return <LoadingModal />
  }

  return (
    <Container className="mt-4">

      {working && <LoadingModal />}

      <div className="d-flex justify-content-between mb-4">
        <h1>Invitations</h1>
        <Button onClick={handleShowCreate}>New</Button>
      </div>

      <Table>
        <thead>
          <tr>
            <th>Id</th>
            <th>Name</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {visibleInvitations.map((inv, index) =>
            <tr key={inv.id}>
              <td>{inv.id}</td>
              <td>{inv.name}</td>
              <td className="text-right">
                <Button
                  size="sm"
                  variant="danger"
                  onClick={() => setDeleteInvitationIndex(index)}
                >
                  <FontAwesomeIcon icon={faTrash} />
                </Button>

                <Button
                  as={Link}
                  variant="light"
                  className="ml-1"
                  size="sm"
                  to={`${match.url}/edit/${inv.id}`}
                >
                  <FontAwesomeIcon icon={faEllipsisH} />
                </Button>
              </td>
            </tr>
          )}
        </tbody>
      </Table>

      <Pagination
        limit={PAGE_LIMIT}
        marginPagesDisplayed={2}
        maxItems={invitations.length}
        onPageChange={({ selected }) => setCurPage(selected)}
        pageRangeDisplayed={5}
      />

      <ConfirmCancel
        prompt="Are you sure you want to delete this invitation?"
        title="Delete Invitation"
        show={!!~deleteInvitationIndex}
        variant="danger"
        onCancel={() => setDeleteInvitationIndex(-1)}
        onConfirm={handleDeleteInvitation}
      />

      <InvitationCreateModal
        show={showCreate}
        onCancel={() => setShowCreate(false)}
        onConfirm={handleCreateInvitation}
      />

      <InvitationDeleteModal
        show={!!~deleteInvitationIndex}
        deleteStore={!!invitations[deleteInvitationIndex] && !invitations[deleteInvitationIndex].acceptedAt}
        onCancel={() => setDeleteInvitationIndex(-1)}
        onConfirm={() => handleDeleteInvitation(false)}
        onConfirmAll={() => handleDeleteInvitation(true)}
      />

    </Container>
  );
};

export default withFirebase(InvitationList);