import React from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { Button, Form, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Formik, Form as FormikForm } from 'formik';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';

const StoreSchema = Yup.object().shape({
  name: Yup.string()
    .required('Store name is required')
    .max(50, 'Store name must be less than 50 characters'),
  phone: Yup.string()
    .required('Phone is required')
    .length(10, 'Phone must be 10 digits with area code'),
  web: Yup.string()
    .url('Invalid url (ex: https://mystore.com)'),
  supportEmail: Yup.string()
    .email('Invalid email'),
});

const SetupStoreInfo = ({
  store = { name: '', phone: '', web: '', supportEmail: '', verified: false, productCount: 0 },
  autoFocus = false,
  onConfirm,
}) => {
  const storeInputRef = React.useRef();

  React.useEffect(() => {
    if (autoFocus) {
      storeInputRef.current.focus();
    }
  }, [autoFocus]);

  return (
    <Formik
      initialValues={store}
      enableReinitialize={true}
      validationSchema={StoreSchema}
      onSubmit={values => onConfirm(values)}
    >

      {({ errors, handleChange, isValid, values }) =>
        <FormikForm>

          <Form.Group>
            <Form.Label>Store Name</Form.Label>
            <Form.Control
              name="name"
              ref={storeInputRef}
              isInvalid={!!errors.name}
              value={values.name}
              onChange={handleChange}
            />
            <Form.Control.Feedback type="invalid">{errors.name}</Form.Control.Feedback>
          </Form.Group>

          <Form.Group>
            <Form.Group>
              <Form.Label>Store Phone</Form.Label>
              <Form.Control
                name="phone"
                type="number"
                isInvalid={!!errors.phone}
                value={values.phone}
                onChange={handleChange}
              />
              <Form.Control.Feedback type="invalid">{errors.phone}</Form.Control.Feedback>
            </Form.Group>
          </Form.Group>

          <Form.Group>
            <Form.Group>
              <Form.Label>Web (optional)</Form.Label>
              <Form.Control
                name="web"
                isInvalid={!!errors.web}
                value={values.web}
                onChange={handleChange}
              />
              <Form.Control.Feedback type="invalid">{errors.web}</Form.Control.Feedback>
            </Form.Group>
          </Form.Group>

          <Form.Group>
            <Form.Group>
              <Form.Label>
                Support Email (optional) &nbsp;
                <OverlayTrigger
                  placement="right"
                  overlay={<Tooltip>Contact email for guests may be different from the sign in email.</Tooltip>}
                >
                  <FontAwesomeIcon icon={faInfoCircle} className="text-success" />
                </OverlayTrigger>
              </Form.Label>

              <Form.Control
                name="supportEmail"
                isInvalid={!!errors.supportEmail}
                value={values.supportEmail}
                onChange={handleChange}
              />
              <Form.Control.Feedback type="invalid">{errors.supportEmail}</Form.Control.Feedback>
            </Form.Group>
          </Form.Group>

          <div className="text-right">
            <Button type="submit" disabled={!isValid}>Next</Button>
          </div>

        </FormikForm>
      }

    </Formik>
  );
};

SetupStoreInfo.propTypes = {
  onConfirm: PropTypes.func.isRequired,
  store: PropTypes.object,
};

export default SetupStoreInfo;