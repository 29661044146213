import React from 'react';
import styled from 'styled-components';
import { Logo } from '../images';

const LogoImage = styled.img`
  max-width: 50px;
`;

const LogoText = () => (
  <div className="text-center d-inline-block">
    <LogoImage className="mb-2" src={Logo} alt="Marker Menu" />
    <h5 className="text-dark">Marker Menu</h5>
  </div>
);

export default LogoText;