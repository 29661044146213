import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import { compose } from 'recompose';
import { withAuth, withFirebase } from '../providers';

const ProtectedRoute = ({
  firebase,
  location,
  user,
  verifyEmail = true,
  ...props
}) => {
  
  const { emailVerified } = user || {};

  if (!user) {
    return <Redirect to={{ ...location, pathname: "/account/signin" }} />;
  }

  // If route requires email verification but user hasn't, send them to account page
  if (verifyEmail && !emailVerified) {
    return <Redirect to={{ ...location, pathname: '/account' }} />
  }

  const { storeId } = user;

  if (location.pathname !== '/account/setup' && !storeId) {
    return <Redirect to={{ ...location, pathname: "/account/setup" }} />;
  }

  return <Route {...props} />;
};

ProtectedRoute.propTypes = {
  verifyEmail: PropTypes.bool,
};

export default compose(
  withAuth,
  withFirebase,
)(ProtectedRoute);