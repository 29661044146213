import React from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, Spinner } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/free-solid-svg-icons';

const ConfirmCancel = ({
  show,
  title,
  prompt,
  icon,
  variant = "primary",
  content,
  working,
  onConfirm,
  onCancel,
}) => (
  <Modal
    show={show}
    onHide={() => onCancel && onCancel()}
    className={`mm-${variant}-top`}
  >
    <Modal.Header closeButton>
      <div className="d-flex justify-content-start align-items-center">
        <span className={`fa-stack fa-2x text-${variant} flex-fill`}>
          <FontAwesomeIcon icon={faCircle} className="fa-stack-2x" />
          <FontAwesomeIcon icon={icon} inverse className="fa-stack-1x" />
        </span>

        <div>
          <Modal.Title className="text-dark">{title}</Modal.Title>
          <span>{prompt}</span>
        </div>
      </div>
    </Modal.Header>

    <Modal.Body>
      {content}
    </Modal.Body>

    <Modal.Footer>
      <Button
        variant="light"
        onClick={() => onCancel && onCancel()}
      >
        Cancel
      </Button>

      <Button
        className="d-flex align-items-center"
        variant={variant}
        disabled={working}
        onClick={() => onConfirm && onConfirm()}
      >
        Confirm {working && <Spinner animation="border" size="sm" className="ml-2" />}
      </Button>
    </Modal.Footer>
  </Modal>
);

ConfirmCancel.propTypes = {
  show: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  icon: PropTypes.object.isRequired,
  prompt: PropTypes.string,
  variant: PropTypes.string,
  working: PropTypes.bool,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default ConfirmCancel;