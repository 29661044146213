import React from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { Helmet } from 'react-helmet';
import { Route, Switch } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';

import { withFirebase } from '../common/providers';
import { LoadingModal } from '../common/components';
import { MenuListPublic, MenuPublic } from '../menus';

import './StoreDetailPublic.scss';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

const HeaderOverlay = styled.div`
  background-color: ${props => props.theme.color};
  opacity: .8;
`;

const PoweredBy = styled.span`
  color: ${props => props.theme.color};
`;

const StoreDetail = ({
  firebase,
  history,
  match,
}) => {
  const [loading, setLoading] = React.useState(true);
  const [store, setStore] = React.useState();
  const { storeId } = match.params;
  const theme = { color: store?.color || '#00a69c'};

  const formatPhone = () => {
    const { phone } = store;

    if (!phone) {
      return null;
    }

    const matched = phone.toString().match(/^(\d{3})(\d{3})(\d{4})/);

    if (matched.length < 4) {
      console.warn('Incorrect phone format');
      return null;
    }

    return `(${matched[1]}) ${matched[2]}-${matched[3]}`;
  };

  React.useEffect(() => {
    const fetchStore = async () => {
      try {
        const apiStore = await firebase.getStore(storeId);

        if (!apiStore) {
          history.push('/error');
          return;
        }

        firebase.logEvent('store_view', { store_id: storeId });

        setStore(apiStore);
      } catch (err) {
        firebase.logError(err);
      }

      setLoading(false);
    };

    fetchStore();
  }, [firebase, history, storeId]);

  if (loading) {
    return <LoadingModal />;
  }

  return (
    <div>

      <Helmet>
        <title>{store.name} | Marker Menu</title>
        <meta name="description" content={`${store.name} contactless menu`} />
      </Helmet>

      <ThemeProvider theme={theme}>

        <section className="store">
          <HeaderOverlay className="store__overlay" />
          <h2 className="store__name">
            {store.name}
            {store.verified &&
              <OverlayTrigger
                placement="right"
                overlay={<Tooltip>Verified</Tooltip>}
              >
                <small className="store__badge">
                  <FontAwesomeIcon icon={faCheckCircle} />
                </small>
              </OverlayTrigger>
            }
          </h2>

          <address className="store__address">
            {store.address}{store.address2 ? ` ${store.address2}` : ''}, {store.city}, {store.state} {store.zip}
          </address>

          {store.phone &&
            <div className="store__phone">
              <a href={`tel:${store.phone}`} target="_blank" rel="noopener noreferrer">
                {formatPhone()}
              </a>
            </div>
          }

          {store.supportEmail &&
            <div className="store__email">
              <a href={`mailto:${store.supportEmail}`}>
                {store.supportEmail}
              </a>
            </div>
          }

          {store.web && 
            <div className="store__web">
              <a href={store.web} target="_blank" rel="noopener noreferrer">
                {store.web}
              </a>
            </div>
          }
        </section>

        <section className="content">
          <div className="content__container">

            <div className="content__body">
              <Switch>
                <Route exact path={`${match.path}/`} component={MenuListPublic} />
                <Route path={`${match.path}/dayparts/:dayPartId/menus/:menuId`} component={MenuPublic} />
              </Switch>
            </div>

            <footer className="footer">
              <PoweredBy className="footer__prefix">Powered by </PoweredBy>
              <span className="footer__company"><a href="https://markermenu.com">Marker Menu</a></span>
            </footer>

          </div>
        </section>

      </ThemeProvider>

    </div>
  );
};

export default withFirebase(StoreDetail);