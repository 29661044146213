import React from 'react';
import { Link } from 'react-router-dom';

const AddNewSection = ({
  children,
  ...props
}) => {
  const btnProps = {
    ...props,
    className: `mm-btn-new ${props.className}`,
  };

  return (
    <Link {...btnProps}>
      {children}
    </Link>
  );
};

export default AddNewSection;