import React from 'react';
import PropTypes from 'prop-types';
import * as d3 from 'd3';
import { Button, Col, Form, ProgressBar, OverlayTrigger, Row, Table, Tooltip } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/free-regular-svg-icons';
import { faCheckCircle, faExclamationTriangle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';

import { withFirebase } from '../common/providers';

const StatusIcons = {
  success: faCheckCircle,
  warning: faExclamationTriangle,
  danger: faTimesCircle,
};

const SampleData = [
  { menu_name: 'Lunch', category_name: 'Appetizers', product_name: 'Wontons', product_desc: 'Pork, shrimp, green onions', product_price: 3.99 },
  { menu_name: 'Lunch', category_name: 'Appetizers', product_name: 'Egg Rolls', product_desc: 'Pork, glass noodles, carrots, cabbage', product_price: 3.99 },
  { menu_name: 'Lunch', category_name: 'Rice', product_name: 'Pineapple Fried Rice', product_desc: 'Pineapple, jasmine rice, eggs', product_price: 10.99 },
  { menu_name: 'Lunch', category_name: 'Rice', product_name: 'Basil Fried Rice', product_desc: 'Basil, jasmine rice, eggs', product_price: 11.99 },
];

const MenuImport = ({
  firebase,
  storeId,
  onMenuImported,
  onProductsImported,
}) => {
  const [working, setWorking] = React.useState(false);
  const [fileSelected, setFileSelected] = React.useState(false);
  const [items, setItems] = React.useState(SampleData);
  const [current, setCurrent] = React.useState(0);
  const total = items.length;

  let fileReader;

  const handleFileRead = () => {
    const { result: csv } = fileReader;
    const { items } = d3.csvParse(csv)
      .reduce((prev, row) => {
        const key = (row.product_name || '').toLowerCase();

        const item = {
          ...row,
          key,
          status: {},
        };

        if (!item.menu_name ||
            !item.category_name ||
            !item.product_name
        ) {
          item.status.danger = 'Skip: Missing field(s)';
        }

        if (prev.keys[key]) {
          item.status.warning = 'Skip: Duplicate';
        }

        return {
          keys: {
            ...prev.keys,
            [key]: item.product_name,
          },
          items: prev.items.concat([item]),
        };
      }, { keys: {}, items: [] });

    setItems(items);
    setFileSelected(true);
  };

  const handleFileSelected = (file) => {
    fileReader = new FileReader();
    fileReader.onloadend = handleFileRead;
    fileReader.readAsText(file);
  };

  const handleProductImport = async (index) => {
    setWorking(true);

    if (index === total) {
      onProductsImported && onProductsImported(items);

      const menu = await postMenu();

      setWorking(false);
      onMenuImported && onMenuImported(menu);

      return;
    }

    setCurrent(index + 1);

    const newItems = items.slice();
    const newItem = newItems[index];
    const keys = Object.keys(newItem.status);

    // Do work when there aren't warnings or errors
    if (!keys.length) {
      try {
        const product = await postProduct({
          name: newItem.product_name,
          description: newItem.product_desc,
          price: newItem.product_price,
        });

        console.log(index, product);

        newItem.product_id = product.id;
        newItem.status.success = 'Success';
      } catch (err) {
        newItem.status.danger = err.message;
      }

      setItems(newItems);
    }

    handleProductImport(index + 1);
  };

  const postMenu = async () => {
    const [first] = items;
    const {categories, categoryOrder, products } = items
      .filter(item => !!item.status.success)
      .reduce((prev, row) => {
        const prodId = row.product_id;
        const catId = row.category_name.toLowerCase().replace(/[^a-z0-9]/g, '');
        const existingCategory = prev.categories[catId] || { name: '', productOrder: [] };
        const categoryOrder = prev.categoryOrder.slice();

        // Groom product
        const product = {
          name: row.product_name,
          description: row.product_desc,
          price: +row.product_price,
          categoryId: catId,
        };
        
        // Add category to category catalog
        const categories = {
          ...prev.categories,
          [catId]: {
            name: row.category_name,
            productOrder: (existingCategory.productOrder).concat([row.product_id]),
          }
        };

        // Add product to product catalog
        const products = {
          ...prev.products,
          [prodId]: product,
        };

        // Add to category array if it doesn't exist
        if (!~categoryOrder.indexOf(catId)) {
          categoryOrder.push(catId);
        }

        return { categories, categoryOrder, products };
      }, { categories: {}, categoryOrder: [], products: {} });

    const newMenu = {
      categories,
      categoryOrder,
      products,
      name: first.menu_name,
      public: true,
      active: true,
    };

    const menu = await firebase.createMenu(storeId, newMenu, 'default');
    return menu;
  };

  const postProduct = async (product) => {
    const newProduct = await firebase.createProduct(storeId, product)
    return newProduct;
  };

  const renderTable = (isSample) => (
    <Table size="sm" hover={!isSample}>
      <thead>
        <tr>
          {!isSample && <th></th>}
          <th className="text-lowercase">menu_name</th>
          <th className="text-lowercase">category_name</th>
          <th className="text-lowercase">product_name</th>
          <th className="text-lowercase">product_desc</th>
          <th className="text-lowercase text-right">product_price</th>
        </tr>
      </thead>

      <tbody>
        {items.map((data, index) => {
          const status = data.status || {};
          const [key] = Object.keys(status);
          const text = status[key];
          const icon = StatusIcons[key];

          return (
            <tr key={index}>
              {!isSample && 
                <td>
                  {!key && <FontAwesomeIcon icon={faCircle} />}
                  {key && 
                    <OverlayTrigger
                      placement="top"
                      overlay={<Tooltip>{text}</Tooltip>}
                    >
                      <FontAwesomeIcon icon={icon} className={`text-${key}`} />
                    </OverlayTrigger>
                  }
                </td>
              }

              <td>{data.menu_name}</td>
              <td>{data.category_name}</td>
              <td>{data.product_name}</td>
              <td>{data.product_desc}</td>
              <td className="text-right">{data.product_price}</td>
            </tr>
          );
        })}
      </tbody>
    </Table>
  );

  if (!fileSelected) {
    return (
      <>
        <Form.Group>
          <Form.File
            custom
            label="Select import file (.csv)"
            accept=".csv"
            onChange={e => handleFileSelected(e.target.files[0])}
          />
        </Form.Group>

        <h5>Instructions</h5>
        <p>The import file must be comma delimited (.csv) with the following fields (case sensitive):</p>

        <ul>
          <li><code>menu_name</code>: Menu name (<em>supports only 1 unique menu name</em>)</li>
          <li><code>category_name</code>: Category name</li>
          <li><code>product_name</code>: Product name</li>
          <li><code>product_desc</code>: Product description</li>
          <li><code>product_price</code>: Product price</li>
        </ul>

        <h5>Example</h5>
        {renderTable(true)}
      </>
    );
  }

  return (
    <>
      <Row>
        <Col md={10}>
          <ProgressBar
            variant="success"
            animated={current !== total}
            now={Math.floor(current / total * 100)}
          />

          <p className="mt-2 text-right">{current}/{total}</p>
        </Col>

        <Col md={2}>
          <Button
            block
            disabled={!total || working}
            onClick={() => handleProductImport(0)}
          >
            Import
          </Button>
        </Col>
      </Row>
      {renderTable(false)}
    </>
  );
};

MenuImport.propTypes = {
  storeId: PropTypes.string.isRequired,
  onMenuImported: PropTypes.func,
  onProductsImported: PropTypes.func,
};

export default withFirebase(MenuImport);