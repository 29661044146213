import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Button, Card } from 'react-bootstrap';
import { Draggable } from 'react-beautiful-dnd';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGripLines, faTimes } from '@fortawesome/free-solid-svg-icons';

const CardBody = styled(Card.Body)`
  display: flex;
  align-items: center;
`;

const Name = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
`;

const Product = ({
  id,
  index,
  product,
  onProductEdit,
  onProductDelete,
}) => {
  const { name } = product;

  return (
    <Draggable draggableId={id} index={index}>
      {(provided, snapshot) =>
        <Card
          className={`mb-2 ${snapshot.isDragging ? 'mm-drag-item' : ''}`}
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          <CardBody className="p-3">
            <FontAwesomeIcon icon={faGripLines} className="mr-3" />

            <div className="flex-fill" style={{ minWidth: 0 }}>
              <Name onClick={() => onProductEdit(id, index, product)}>
                {name}
              </Name>
            </div>

            <Button variant="default" size="sm" onClick={() => onProductDelete(id)}>
              <FontAwesomeIcon icon={faTimes} />
            </Button>
          </CardBody>
        </Card>
      }
    </Draggable>
  );
};

Product.propTypes = {
  id: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  product: PropTypes.object.isRequired,
  onProductEdit: PropTypes.func.isRequired,
  onProductDelete: PropTypes.func.isRequired,
};

export default Product;