import React from 'react';
import * as Yup from 'yup';
import { Button, Form, InputGroup, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Formik, Form as FormikForm } from 'formik';
import { compose } from 'recompose';
import { ERROR_GENERIC } from '../common/Constants';
import { LoadingModal, Toast } from '../common/components';
import { withAuth, withFirebase } from '../common/providers';

import SetupAddress from './SetupAddress';
import SetupContact from './SetupContact';
import VerifiedIcon from './VerifiedIcon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBuilding, faEnvelope, faLink, faInfoCircle } from '@fortawesome/free-solid-svg-icons';

const ProfileSchema = Yup.object().shape({
  name: Yup.string()
    .required('Store name is required'),
});

const DefaultStore = {
  name: '',
  verified: false,
};

const Profile = ({
  firebase,
  user,
}) => {
  const { email, storeId } = user;
  const [loading, setLoading] = React.useState(true);
  const [working, setWorking] = React.useState(false);
  const [store, setStore] = React.useState(DefaultStore);

  const handleUpdateNonEssenentialStoreInfo = async (storeInfo) => {
    try {
      setWorking(true);

      await firebase.updateStore(storeId, storeInfo);

      Toast({ success: 'Saved!' });
    } catch (err) {
      Toast({ error: ERROR_GENERIC });
    }

    setWorking(false);
  };

  const handleStoreNameUpdate = async (values) => {
    try {
      setWorking(true);

      await firebase.updateStore(storeId, values);

      await firebase.setClaims({ store: values.name });

      await firebase.refreshToken();

      Toast({ success: 'Saved!' });

      setTimeout(() => window.location.reload(), 1000);
    } catch (err) {
      Toast({ error: ERROR_GENERIC });
      setWorking(false);
    }
  };

  React.useEffect(() => {
    const fetchProfile = async () => {
      try {
        const apiStore = await firebase.getStore(storeId);
        const curStore = {
          ...DefaultStore,
          ...apiStore,
        };

        setStore(curStore);
      } catch (err) {
        firebase.logError(err);
        Toast({ error: ERROR_GENERIC });
      }

      setLoading(false);
    };

    fetchProfile();
  }, [firebase, email, storeId]);

  if (loading) {
    return <LoadingModal />;
  }

  return (
    <>

      {!loading && (
        <>
          {working && <LoadingModal />}


          <div className="mb-4">
            <h1>
              Store <VerifiedIcon verified={store.verified} />
            </h1>

            <Form.Group>
              <Form.Label>Email</Form.Label>
              <InputGroup>
                <InputGroup.Prepend>
                  <InputGroup.Text>
                    <FontAwesomeIcon icon={faEnvelope} />
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <Form.Control value={email} disabled={true} />
              </InputGroup>
            </Form.Group>

            <Form.Group>
              <Form.Label>
                Public Link&nbsp;
                <OverlayTrigger
                  placement="right"
                  overlay={<Tooltip>This is the link that your guests will go to.</Tooltip>}
                >
                  <FontAwesomeIcon icon={faInfoCircle} />
                </OverlayTrigger>
              </Form.Label>

              <InputGroup>
                <InputGroup.Prepend>
                  <InputGroup.Text>
                    <FontAwesomeIcon icon={faLink} />
                  </InputGroup.Text>
                </InputGroup.Prepend>

                <Form.Control readOnly={true} value={store.url ? store.url : 'Generating url...'} />
              </InputGroup>
            </Form.Group>

            <Formik
              enableReinitialize={true}
              initialValues={store}
              validationSchema={ProfileSchema}
              onSubmit={handleStoreNameUpdate}
            >
              {({ errors, handleChange, isSubmitting, isValid, values }) =>
                <FormikForm>

                  <Form.Group>
                    <Form.Label>Store Name</Form.Label>
                    <InputGroup>
                      <InputGroup.Prepend>
                        <InputGroup.Text>
                          <FontAwesomeIcon icon={faBuilding} />
                        </InputGroup.Text>
                      </InputGroup.Prepend>

                      <Form.Control
                        name="name"
                        autoFocus={true}
                        isInvalid={!!errors.name}
                        value={values.name}
                        onChange={handleChange}
                      />
                      <Form.Control.Feedback type="invalid">{errors.name}</Form.Control.Feedback>
                    </InputGroup>
                  </Form.Group>

                  <div className="d-flex justify-content-between">
                    <Button
                      variant="outline-danger"
                      onClick={() => firebase.signOut()}
                    >
                      Sign Out
                    </Button>
                    <Button
                      type="primary"
                      disabled={isSubmitting || !isValid}
                    >
                      Save
                    </Button>
                  </div>
                </FormikForm>
              }
            </Formik>

          </div>
          <hr />

          <div className="mb-4">
            <h1>Contact</h1>
            <SetupContact contact={{ ...store }} onConfirm={handleUpdateNonEssenentialStoreInfo} />
          </div>
          <hr />

          <div>
            <h1>Location</h1>
            <SetupAddress address={{ ...store }} onConfirm={handleUpdateNonEssenentialStoreInfo} />
          </div>

        </>
      )}
    </>
  );
};

export default compose(
  withAuth,
  withFirebase,
)(Profile);