/**
 * Toggle loading flag.
 * @param {object} state State of menu edit.
 */
export const toggleLoading = (state) => ({ ...state, loading: !state.loading });

/**
 * Toggle working flag.
 * @param {object} state State of menu edit.
 */
export const toggleWorking = (state) => ({ ...state, working: !state.working });