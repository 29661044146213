import React from 'react';
import { ListGroup } from 'react-bootstrap';
import { LoadingModal } from '../common/components';
import { withFirebase } from '../common/providers';
import { Link } from 'react-router-dom';

const MenuListPublic = ({
  firebase,
  match,
}) => {
  const [loading, setLoading] = React.useState(true);
  const [dayPart, setDayPart] = React.useState();
  const { storeId } = match.params;

  React.useEffect(() => {
    const fetchMenus = async () => {
      try {
        const apiDayPart = await firebase.getDayPart(storeId, 'default');

        firebase.logEvent('menu_list_view', {
          store_id: storeId,
        });

        setDayPart(apiDayPart);
      } catch (err) {
        firebase.logError(err);
      }

      setLoading(false);
    };

    fetchMenus();
  }, [firebase, storeId]);

  if (loading) {
    return <LoadingModal />;
  }

  return (
    <>

        <ListGroup variant="flush">
          {dayPart.menuOrder.map(menuId =>
            <ListGroup.Item
              action
              key={menuId}
              as={Link}
              to={`/stores/${storeId}/dayparts/default/menus/${menuId}`}
            >
              <h5 className="text-dark mb-0 py-3">{dayPart.menus[menuId].name}</h5>
            </ListGroup.Item>
          )}
        </ListGroup>

        {!dayPart.menuOrder.length && 
          <ListGroup variant="flush">
            <ListGroup.Item><em>No menus available</em></ListGroup.Item>
          </ListGroup>
        }

    </>
  );
};

export default withFirebase(MenuListPublic);