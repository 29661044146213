/**
 * Delete a category.
 */
export const CATEGORY_DELETE_CONFIRM = 'categoryDelete';
export const categoryDelete = (id, index, handler) => ({ id, index, handler, type: CATEGORY_DELETE_CONFIRM });

/**
 * Start new category.
 */
export const CATEGORY_CREATE = 'categoryCreate';
export const categoryCreate = () => ({ type: CATEGORY_CREATE });

/**
 * Start editing a category.
 */
export const CATEGORY_EDIT = 'categoryEdit';
export const categoryEdit = (id, index, category) => ({ id, index, category, type: CATEGORY_EDIT });

/**
 * Cancel editing category.
 */
export const CATEGORY_EDIT_CANCEL = 'categoryEditCancel';
export const categoryEditCancel = () => ({ type: CATEGORY_EDIT_CANCEL });

/**
 * Update a category.
 */
export const CATEGORY_UPDATE = 'categoryUpdate';
export const categoryUpdate = (id, category) => ({ id, category, type: CATEGORY_UPDATE });

/**
 * Delete menu.
 */
export const MENU_DELETE = 'menuDelete';
export const menuDelete = (handler) => ({ handler, type: MENU_DELETE });

/**
 * Cancel menu deletion.
 */
export const MENU_DELETE_CANCEL = 'menuDeleteCancel';
export const menuDeleteCancel = () => ({ type: MENU_DELETE_CANCEL });

/**
 * Edit menu.
 */
export const MENU_EDIT = 'menuEdit';
export const menuEdit = (menu) => ({ menu, type: MENU_EDIT });

/**
 * Cancel menu edit.
 */
export const MENU_EDIT_CANCEL = 'menuEditCancel';
export const menuEditCancel = () => ({ type: MENU_EDIT_CANCEL });

/**
 * Load menu.
 */
export const MENU_LOAD = 'menuLoad';
export const menuLoad = (menu) => ({ menu, type: MENU_LOAD });

/**
 * Update menu.
 */
export const MENU_UPDATE = 'menuUpdate';
export const menuUpdate = (menu) => ({ menu, type: MENU_UPDATE });

/**
 * Add a product to menu.
 */
export const PRODUCT_ADD = 'productAdd';
export const productAdd = (catId) => ({ catId, type: PRODUCT_ADD });

/**
 * Delete a product.
 */
export const PRODUCT_DELETE = 'productDelete';
export const productDelete = (catId, prodId, prodIndex, handler) => ({ catId, prodId, prodIndex, handler, type: PRODUCT_DELETE });

/**
 * Edit a product.
 */
export const PRODUCT_EDIT = 'productEdit';
export const productEdit = (id, catId, prodIndex, product) => ({ id, catId, prodIndex, product, type: PRODUCT_EDIT });

/**
 * Cancel product edit.
 */
export const PRODUCT_EDIT_CANCEL = 'productEditCancel';
export const productEditCancel = () => ({ type: PRODUCT_EDIT_CANCEL });

/**
 * Load all products.
 */
export const PRODUCT_LIST_LOAD = 'productListLoad';
export const productListLoad = (products) => ({ products, type: PRODUCT_LIST_LOAD })

/**
 * Update product.
 */
export const PRODUCT_UPDATE = 'productUpdate';
export const productUpdate = (id, catId, product) => ({ id, catId, product, type: PRODUCT_UPDATE });

/**
 * Toggle loading flag.
 */
export const TOGGLE_LOADING = 'toggleLoading';
export const toggleLoading = () => ({ type: TOGGLE_LOADING });

/**
 * Toggle working flag.
 */
export const TOGGLE_WORKING = 'toggleWorking';
export const toggleWorking = () => ({ type: TOGGLE_WORKING });