import React from 'react';
import styled from 'styled-components';
import { OverlayTrigger, Popover, Tooltip } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { withAuth } from '../common/providers';

const UnverifiedContainer = styled.span`
  cursor: pointer;
`;

const VerifiedIcon = ({
  user,
  verified,
}) => {
  const { storeId } = user;

  if (verified) {
    return (
      <OverlayTrigger
        placement="right"
        overlay={<Tooltip>Verified</Tooltip>}
      >
        <FontAwesomeIcon className="text-info" icon={faCheckCircle} />
      </OverlayTrigger>
    );
  }

  return (
    <OverlayTrigger
      placement="right"
      trigger="click"
      overlay={
        <Popover>
          <Popover.Title as="h3">Verification</Popover.Title>
          <Popover.Content>
            <p>
              Earn trust by showing a verification badge next to your store name. 
              In order to verify, email <a target="_blank" href={`mailto:support@markermenu.com?subject=Account Verification: ${storeId}`} rel="noopener noreferrer">support@markermenu.com</a> with your business license. 
              The business name on the business license <em>must</em> match the name you entered as your store name. This may take 2-3 business days.
            </p>

            <a
              target="_blank"
              rel="noopener noreferrer"
              className="btn btn-block btn-secondary btn-sm"
              href={`mailto:support@markermenu.com?subject=Account Verification: ${storeId}`}
            >
              Verify
            </a>
          </Popover.Content>
        </Popover>
      }
    >
      <UnverifiedContainer>
        <FontAwesomeIcon className="text-warning" icon={faExclamationTriangle} />
      </UnverifiedContainer>
    </OverlayTrigger>
  );
};

export default withAuth(VerifiedIcon);