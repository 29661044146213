import React from 'react';
import Numeral from 'numeral';
import * as d3 from 'd3';
import { Alert, Button, Container, ProgressBar, Table, OverlayTrigger, Tooltip, Form } from 'react-bootstrap';
import { compose } from 'recompose';
import { withAuth, withFirebase } from '../common/providers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/free-regular-svg-icons';
import { faCheckCircle, faExclamationTriangle, faTimesCircle, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { LoadingModal } from '../common/components';

const ProductImport = ({
  firebase,
  user,
}) => {
  const [loading, setLoading] = React.useState(true);
  const [fileSelected, setFileSelected] = React.useState(false);
  const [importing, setImporting] = React.useState(false);
  const [current, setCurrent] = React.useState(0);
  const [products, setProducts] = React.useState([]);
  const [productKeys, setProductKeys] = React.useState({});
  const total = products.length;
  const { storeId } = user;

  let fileReader;

  const handleFileRead = () => {
    const { result: csv } = fileReader;
    const { products } = d3.csvParse(csv)
      .filter(prod => prod.name && prod.description)
      // Groom data
      .map(prod => ({
        name: prod.name,
        description: prod.description,
        price: +prod.price || 0,
        daypart: 'default',
      }))
      // Filter duplicates
      .reduce((prev, prod) => {
        const key = prod.name.toLowerCase();

        const product = {
          ...prod,
          price: +prod.price,
          duplicate: !!prev.keys[key],
        };

        return {
          keys: {
            ...prev.keys,
            [key]: prod.name,
          },
          products: prev.products.concat([product]),
        };
      }, { keys: { ...productKeys }, products: [] });

    setProducts(products);
    setFileSelected(true);
  };

  const handleFileSelected = (file) => {
    if (file) {
      fileReader = new FileReader();
      fileReader.onloadend = handleFileRead;
      fileReader.readAsText(file);
    }
  };

  const handleImport = async (index) => {
    setImporting(true);

    const newProducts = products.slice();
    const newProduct = newProducts[index];

    if (index === total) {
      setImporting(false);
      return;
    }

    if (!newProduct.duplicate && !newProduct.error && !newProduct.success) {
      setCurrent(index + 1);

      try {
        await postProduct({
          name: newProduct.name,
          description: newProduct.description,
          price: newProduct.price,
        });

        newProduct.success = 'Imported';
      } catch (err) {
        newProduct.error = `Error: ${err.message}`;
      }

      setProducts(newProducts);
    }

    handleImport(index + 1);
  };

  const postProduct = async (product) => {
    await firebase.createProduct(storeId, product);
  };

  React.useEffect(() => {
    const fetchProducts = async () => {
      try {
        const apiProducts = await firebase.getProducts(storeId);
        const apiKeys = apiProducts.reduce((keys, prod) => ({
          ...keys,
          [prod.key]: prod.name,
        }), {});

        setProductKeys(apiKeys);
        setLoading(false);
      } catch (err) {
        firebase.logError(err);
      }
    };

    fetchProducts();
  }, [firebase, storeId]);

  if (loading) {
    return <LoadingModal />
  }

  if (!fileSelected) {
    return (
      <Container className="mt-4">
        <h1>Import Products</h1>
        <p>The import file must be comma delimited (.csv) with the following fields (case sensitive):</p>
        <ul className="mb-4">
          <li><code>name</code>: Name of the product</li>
          <li><code>description</code>: Description of the product</li>
          <li><code>price</code>: Price of the product</li>
        </ul>

        <Form.Group className="mb-4">
          <Form.File
            custom
            label="Select import file (.csv)"
            onChange={e => handleFileSelected(e.target.files[0])}
          />
        </Form.Group>


        <h5>Example</h5>

        <Table bordered className="bg-dark text-white">
          <thead>
            <tr>
              <th className="text-lowercase">name</th>
              <th className="text-lowercase">description</th>
              <th className="text-lowercase">price</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Cheese Burger</td>
              <td>Angus beef, american cheese, iceberg lettuce</td>
              <td>5.99</td>
            </tr>
            <tr>
              <td>Fries</td>
              <td>Idaho potatoes, sea salt, fresh cracked pepper</td>
              <td>3.99</td>
            </tr>
          </tbody>
        </Table>
      </Container>
    );
  }

  return (
    <Container className="mt-4">
      <div className="d-flex justify-content-between">
        <h1>Import Products</h1>

        <Button
          variant="primary"
          disabled={!total || importing}
          onClick={() => handleImport(0)}
        >
          Import
        </Button>
      </div>

      {current > 0 && current < total &&
        <Alert variant="info">
          <FontAwesomeIcon icon={faInfoCircle} /> Do not refresh or go back while importing.
        </Alert>
      }

      <div className="mt-4">
        <ProgressBar
          variant="success"
          animated={current !== total}
          now={Math.floor(current / total * 100)}
        />
        <p className="mt-1 text-right">
          {total === current &&
            <OverlayTrigger
              placement="left"
              overlay={<Tooltip>Import complete!</Tooltip>}
            >
              <FontAwesomeIcon icon={faCheckCircle} className="text-success mr-1" />
            </OverlayTrigger>
          }
          <span>{current}/{total}</span>
        </p>
      </div>

      <Table hover={!!total} className="mt-4">
        <thead>
          <tr>
            <th></th>
            <th>Name</th>
            <th>Description</th>
            <th>Price</th>
          </tr>
        </thead>
        <tbody>
          {products.map((prod, index) =>
            <tr key={index}>
              <td>
                {(prod.success || !!prod.error) &&
                  <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip>{prod.success || prod.error}</Tooltip>}
                  >
                    <FontAwesomeIcon
                      className={`text-${prod.success ? 'success' : 'danger'}`}
                      icon={prod.success ? faCheckCircle : faTimesCircle}
                    />
                  </OverlayTrigger>
                }

                {prod.duplicate &&
                  <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip>Duplicate</Tooltip>}
                  >
                    <FontAwesomeIcon className="text-warning" icon={faExclamationTriangle} />
                  </OverlayTrigger>
                }

                {(!prod.success && !prod.error && !prod.duplicate) && <FontAwesomeIcon icon={faCircle} />}
              </td>

              <td>{prod.name}</td>
              <td className="text-truncate">{prod.description}</td>
              <td>{Numeral(prod.price).format('$0,0.00')}</td>
            </tr>
          )}

          {!products.length &&
            <tr>
              <td></td>
              <td colSpan={3}>
                <em>No products to import.</em>
              </td>
            </tr>
          }

        </tbody>
      </Table>


    </Container>
  );
};

export default compose(
  withAuth,
  withFirebase,
)(ProductImport);