import React from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { v4 as uuid } from 'uuid';
import { extname } from 'path';
import { compose } from 'recompose';
import { Redirect } from 'react-router-dom';
import { Badge, Button, Form, Modal, Nav, InputGroup, Spinner } from 'react-bootstrap';
import { Formik, Form as FormikForm } from 'formik';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBox, faCircle } from '@fortawesome/free-solid-svg-icons';

import { withAuth, withFirebase } from '../common/providers';
import { ImageUpload, Toast } from '../common/components';
import { ERROR_GENERIC } from '../common/Constants';

const ProductSchema = Yup.object().shape({
  name: Yup.string()
    .required('Name is required')
    .max(50, 'Max 50 characters'),
  description: Yup.string()
    .required('Description is required'),
  price: Yup.number()
    .required('Price is required')
    .min(0, 'Minimum price must be $0'),
});

const ProductEdit = ({
  firebase,
  user,
  match,
  history,
  id,
  onConfirm,
  onCancel,
  onError,
}) => {
  const [loading, setLoading] = React.useState(true);
  const [product, setProduct] = React.useState();
  const [updatedProduct, setUpdatedProduct] = React.useState();
  const [show, setShow] = React.useState(true);
  const [imageLoading, setImageLoading] = React.useState(true);
  const { storeId } = user;
  const productId = id ? id : match.params.productId;
  const curName = (product || {}).name;
  const nameRef = React.useRef();

  const handleImageAccepted = ({ files, setImagePath, setImageUrl }) => {
    const file = files[0];

    if (file) {
      setImageLoading(true);

      const name = uuid();
      const ext = extname(file.name);
      const imagePath = `/stores/${storeId}/products/${name}${ext}`;

      // Upload file
      const uploadTask = firebase
        .getImageRef(imagePath)
        .put(file);

      uploadTask.on('state_changed',
        (snapshot) => { /* Snapshot for progress */ },
        (err) => Toast({ error: err.message }),
        () => {
          uploadTask.snapshot.ref.getDownloadURL()
            .then(url => {
              setImageUrl(url);
              setImagePath(imagePath);
              setImageLoading(false);
            });
        },
      );
    }
  };

  const handleImageDeleted = (clearImageRefs) => clearImageRefs();

  const handleUpdateProduct = async (values) => {
    try {
      // Check for duplicate item if name was changed
      if (curName !== values.name) {
        const existingProduct = await firebase.getProductByName(storeId, values.name);

        // If there's a diff prod with same name
        if (existingProduct && existingProduct.id !== productId) {
          Toast({ error: 'Duplicate product!' });
          return;
        }
      }

      const newProduct = {
        ...values,
        key: values.name.toLowerCase(),
      };

      await firebase.updateProduct(storeId, productId, newProduct);

      setUpdatedProduct({ ...newProduct, id: productId });

      Toast({ success: `${values.name} updated!` });
    } catch(err) {
      firebase.logError(err);
      Toast({ error: ERROR_GENERIC });
    }

    if (onConfirm) {
      onConfirm(id, values);
      return;
    }

    setShow(false);
  };

  const handleCancel = () => onCancel ? onCancel() : setShow(false);

  const handleOnExited = () => {
    if (!onConfirm) {
      gotoProductList();
    }
  };

  const gotoProductList = () => history.push('/account/products', { product: updatedProduct });

  React.useEffect(() => {
    const fetchImageUrl = async () => {
      setImageLoading(false);
    };

    if (product) {
      fetchImageUrl();
    }
  }, [firebase, product]);

  React.useEffect(() => {
    const fetchProduct = async () => {
      try {
        const apiProduct = await firebase.getProduct(storeId, productId);

        setProduct(apiProduct);
        setUpdatedProduct({ ...apiProduct, id: productId });
        setLoading(false);

        nameRef.current.focus();
      } catch(err) {
        firebase.logError(err);
        Toast({ error: ERROR_GENERIC });

        onError && onError(err);
      }
    };

    fetchProduct();
  }, [firebase, storeId, productId, onError]);

  if (loading) {
    return null;
  }

  if (!product) {
    return <Redirect to="/error" />
  }

  return (
    <Modal
      className="mm-success-top"
      show={show}
      onHide={() => onCancel ? onCancel() : setShow(false)}
      onExited={handleOnExited}
    >

      <Formik
        enableReinitialize={true}
        initialValues={product}
        validationSchema={ProductSchema}
        onSubmit={handleUpdateProduct}
      >
        {({ errors, handleBlur, handleChange, isSubmitting, isValid, setFieldValue, values }) =>

          <FormikForm>

            <Modal.Header closeButton>
              <div className="d-flex justify-content-start align-items-center">
                <span className="fa-stack fa-2x text-primary">
                  <FontAwesomeIcon icon={faCircle} className="fa-stack-2x" />
                  <FontAwesomeIcon inverse icon={faBox} className="fa-stack-1x" />
                </span>

                <div>
                  <Modal.Title>Edit Product</Modal.Title>
                  <span>Update product information</span>
                </div>
              </div>
            </Modal.Header>

            {loading && <Spinner animation="border" />}

            {!loading &&
              <Modal.Body className="px-0">

                <Nav activeKey="detail" className="mm-nav px-4">
                  <Nav.Item>
                    <Nav.Link eventKey="detail" active>Detail</Nav.Link>
                  </Nav.Item>
                </Nav>
                <hr className="mt-0" />

                <div className="px-4">
                  <Form.Group>
                    <Form.Label>Name</Form.Label>
                    <Form.Control
                      name="name"
                      ref={nameRef}
                      autoFocus={true}
                      isInvalid={!!errors.name}
                      value={values.name}
                      onChange={handleChange}
                    />
                    <Form.Control.Feedback type="invalid">{errors.name}</Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group>
                    <Form.Label>Desciption</Form.Label>
                    <Form.Control
                      name="description"
                      as="textarea"
                      isInvalid={!!errors.description}
                      value={values.description}
                      onChange={handleChange}
                    />
                    <Form.Control.Feedback type="invalid">{errors.description}</Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group>
                    <Form.Label>
                      Price {!values.price && <Badge variant="warning">Free</Badge>}
                    </Form.Label>

                    <InputGroup>
                      <InputGroup.Prepend>
                        <InputGroup.Text>$</InputGroup.Text>
                      </InputGroup.Prepend>
                      <Form.Control
                        name="price"
                        type="number"
                        step="0.01"
                        isInvalid={!!errors.price}
                        value={values.price}
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                      <Form.Control.Feedback type="invalid">{errors.price}</Form.Control.Feedback>
                    </InputGroup>
                  </Form.Group>

                  <ImageUpload
                    imageUrl={values.imageUrl}
                    working={imageLoading}
                    onAccepted={files => handleImageAccepted({
                      files,
                      setImagePath: (imagePath) => setFieldValue('imagePath', imagePath),
                      setImageUrl: (imageUrl) => setFieldValue('imageUrl', imageUrl),
                    })}
                    onDeleted={() => handleImageDeleted(() => {
                      setFieldValue('imagePath', null);
                      setFieldValue('imageUrl', null);
                    })}
                  />

                </div>
              </Modal.Body>
            }

            <Modal.Footer>
              <Button
                variant="light"
                onClick={handleCancel}
              >
                Cancel
              </Button>

              <Button
                className="d-flex align-items-center"
                variant="primary"
                type="submit"
                disabled={loading || isSubmitting || !isValid}
              >
                Save {isSubmitting && <Spinner animation="border" size="sm" className="ml-2" />}
              </Button>

            </Modal.Footer>

          </FormikForm>
        }
      </Formik>

    </Modal>
  );
};

ProductEdit.propTypes = {
  /**
   * The product id if using this from another component;
   */
  id: PropTypes.string,

  /**
   * Triggers when used from another component.
   */
  onConfirm: PropTypes.func,

  /**
   * Triggers when used from another component.
   */
  onCancel: PropTypes.func,

  /**
   * Triggers when used from another component.
   */
  onError: PropTypes.func,
};

export default compose(
  withAuth,
  withFirebase,
)(ProductEdit);