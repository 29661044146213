import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Form, Modal, Button, Spinner } from 'react-bootstrap';
import { Formik, Form as FormikForm } from 'formik';
import { ProductSchema } from '../products/schemas';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowAltCircleLeft } from '@fortawesome/free-solid-svg-icons';
import { getSlug } from './reducers/helpers';

const BackIcon = styled(FontAwesomeIcon)`
  cursor: pointer;
  margin-right: .8rem;
  color: var(--primary);
`;

const InitialValue = {
  name: '',
  description: '',
  price: 0,
};

const ProductCreate = ({
  name = '',
  isActive,
  productList,
  working,
  onBack,
  onCancel,
  onConfirm,
}) => {
  const nameRef = React.useRef();

  const productListKeys = React.useMemo(() => {
    return productList.reduce((keys, prod) => {
      const slug = getSlug(prod.name);

      return {
        ...keys,
        [slug]: prod.name,
      };
    }, {});
  }, [productList]);

  const handleValidation = async (values) => {
    const errors = {};

    try {
      await ProductSchema.validate(values, { abortEarly: false });
    } catch (err) {
      const validationErrors = err.inner
        .reduce((errCatalog, curErr) => {
          return {
            ...errCatalog,
            [curErr.path]: curErr.message,
          };
        }, errors);

      Object.assign(errors, validationErrors);
    }

    if (!errors['name']) {
      const slug = getSlug(values.name);

      if (productListKeys[slug]) {
        errors.name = 'Duplicate product';
      }
    }

    return errors;
  };

  React.useEffect(() => {
    if (isActive) {
      nameRef.current.focus();
    }
  }, [isActive]);

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={{ ...InitialValue, name }}
        validate={handleValidation}
        onSubmit={onConfirm}
      >
        {({ errors, handleChange, isSubmitting, isValid, values }) =>
          <FormikForm>
            <Modal.Body className="search">

              <div className="d-flex justify-content-start align-items-start">
                <BackIcon
                  icon={faArrowAltCircleLeft}
                  size="2x"
                  onClick={() => onBack()}
                />

                <div>
                  <h4>Quick Add</h4>
                  <p>Create a new product</p>
                </div>

              </div>

              <Form.Group>
                <Form.Label>Name</Form.Label>
                <Form.Control
                  name="name"
                  ref={nameRef}
                  isInvalid={!!errors.name}
                  value={values.name}
                  onChange={handleChange}
                />
                <Form.Control.Feedback type="invalid">{errors.name}</Form.Control.Feedback>
              </Form.Group>

              <Form.Group>
                <Form.Label>Description</Form.Label>
                <Form.Control
                  name="description"
                  as="textarea"
                  isInvalid={!!errors.description}
                  value={values.description}
                  onChange={handleChange}
                />
                <Form.Control.Feedback type="invalid">{errors.description}</Form.Control.Feedback>
              </Form.Group>

              <Form.Group>
                <Form.Label>Price</Form.Label>
                <Form.Control
                  name="price"
                  type="number"
                  step="0.01"
                  isInvalid={!!errors.price}
                  value={values.price}
                  onChange={handleChange}
                />
                <Form.Control.Feedback type="invalid">{errors.price}</Form.Control.Feedback>
              </Form.Group>

            </Modal.Body>

            <Modal.Footer>
              <Button variant="light" onClick={() => onCancel()}>Cancel</Button>

              <Button
                type="submit"
                disabled={!isValid || isSubmitting || working}
              >
                Add {(isSubmitting || working) && <Spinner animation="border" size="sm" className="ml-2" />}
              </Button>
            </Modal.Footer>

          </FormikForm>

        }
      </Formik>
    </>
  );
};

ProductCreate.propTypes = {
  name: PropTypes.string,
  isActive: PropTypes.bool.isRequired,
  productList: PropTypes.array.isRequired,
  onBack: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};

export default ProductCreate;