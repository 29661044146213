import * as actionTypes from './actions';
import * as category from './category';
import * as loaders from './loaders';
import * as menu from './menu';
import * as product from './product';

const allReducers = {
  ...category,
  ...loaders,
  ...menu,
  ...product,
};

export const reducer = (state, action) => {
  const curReducer = allReducers[action.type];

  if (!curReducer) {
    throw new Error(`Unsupported action type: ${action.type}`);
  }

  return curReducer(state, action);
};

export { initialState } from './state';
export const actions = { ...actionTypes };