import React from 'react';
import Firebase from './Firebase';

const FirebaseContext = React.createContext();

const FirebaseProvider = ({ children }) => (
  <FirebaseContext.Provider value={{ firebase: new Firebase() }}>
    {children}
  </FirebaseContext.Provider>
);

const withFirebase = Component => props => (
  <FirebaseContext.Consumer>
    {value => <Component {...props} {...value} />}
  </FirebaseContext.Consumer>
);

export default FirebaseProvider;
export {
  FirebaseContext,
  withFirebase,
};