import React from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { Button, Col, Form, Row, Collapse } from 'react-bootstrap';
import { Formik, Form as FormikForm } from 'formik';
import { states } from '../../common/Constants';

const LocationSchema = Yup.object().shape({
  address: Yup.string().required('Address is required'),
  city: Yup.string().required('City is required'),
  state: Yup.string().required('State is required'),
  zip: Yup.string().required('Zip is required'),
  address2: Yup.string(),
})

const StoreLocation = ({
  store,
  editing,
  onEditing,
  onConfirm,
}) => (
  <section className="section">
    <Row>
      <Col lg={4} className="section__title">
        <h5>Store Location</h5>
      </Col>

      {!editing &&
        <>
          <Col lg={6} className="section__desc">
            Update store address
          </Col>

          <Col lg={2} className="section__edit">
            <Button variant="outline-primary" size="sm" onClick={() => onEditing()}>
              Edit
            </Button>
          </Col>
        </>
      }
    </Row>

    <Collapse in={editing}>
      <div>

        <Formik
          initialValues={{ ...store }}
          validationSchema={LocationSchema}
          onSubmit={onConfirm}
        >
          {({ errors, handleChange, isValid, values }) =>
            <FormikForm>
              <Form.Group as={Row}>
                <Form.Label column lg={4}>Street</Form.Label>
                <Col lg={8}>
                  <Form.Control
                    name="address"
                    isInvalid={!!errors.address}
                    value={values.address}
                    onChange={handleChange}
                  />
                  <Form.Control.Feedback type="invalid">{errors.address}</Form.Control.Feedback>
                </Col>
              </Form.Group>

              <Form.Group as={Row}>
                <Form.Label column lg={4}>Unit/Ste (optional)</Form.Label>
                <Col lg={8}>
                  <Form.Control
                    name="address2"
                    isInvalid={!!errors.address2}
                    value={values.address2}
                    onChange={handleChange}
                  />
                </Col>
              </Form.Group>

              <Form.Group as={Row}>
                <Form.Label column lg={4}>City</Form.Label>
                <Col lg={8}>
                  <Form.Control
                    name="city"
                    isInvalid={!!errors.city}
                    value={values.city}
                    onChange={handleChange}
                  />
                  <Form.Control.Feedback type="invalid">{errors.city}</Form.Control.Feedback>
                </Col>
              </Form.Group>

              <Form.Group as={Row}>
                <Form.Label column lg={4}>State</Form.Label>
                <Col lg={8}>
                  <Form.Control
                    as="select"
                    name="state"
                    isInvalid={!!errors.state}
                    value={values.state}
                    onChange={handleChange}
                  >
                    {states.map(st => <option key={st} value={st}>{st}</option>)}
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">{errors.state}</Form.Control.Feedback>
                </Col>
              </Form.Group>

              <Form.Group as={Row}>
                <Form.Label column lg={4}>Zip</Form.Label>
                <Col lg={8}>
                  <Form.Control
                    name="zip"
                    isInvalid={!!errors.zip}
                    value={values.zip}
                    onChange={handleChange}
                  />
                  <Form.Control.Feedback type="invalid">{errors.zip}</Form.Control.Feedback>
                </Col>
              </Form.Group>

              <Row>
                <Col lg={{ span: 8, offset: 4 }}>
                  <Button type="submit" disabled={!isValid}>Save</Button>
                </Col>
              </Row>

            </FormikForm>
          }
        </Formik>

      </div>
    </Collapse>
    
  </section>

);

StoreLocation.propTypes = {
  store: PropTypes.object.isRequired,
  editing: PropTypes.bool.isRequired,
  onEditing: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};

export default StoreLocation;