import React from 'react';
import PropTypes from 'prop-types';
import { kebabCase } from 'lodash';
import { v4 as uuid } from 'uuid';
import * as Yup from 'yup';
import { Button, Form, Modal, Spinner } from 'react-bootstrap';
import { Formik, Form as FormikForm } from 'formik';

const CategoryNew = ({
  id,
  category,
  existingKeys = {},
  show,
  title,
  working,
  onConfirm,
  onCancel,
}) => {
  const CategorySchema = React.useMemo(() => Yup.object().shape({
    name: Yup.string()
      .required('Name is required')
      .max(100, 'Name must be 100 characters max')
      .test('is-duplicate', 'Duplicate category', value => !existingKeys[kebabCase(value)]),
  }), [existingKeys]);

  const nameRef = React.useRef();

  const handleSubmit = (category) => onConfirm(id || uuid(), category);

  return (
    <Modal
      className="mm-success-top"
      show={show}
      onEntered={() => nameRef.current.focus()}
      onHide={() => onCancel()}
    >
      <Formik
        enableReinitialize={true}
        initialValues={{...category}}
        validationSchema={CategorySchema}
        onSubmit={handleSubmit}
      >
        {({ errors, handleChange, isSubmitting, isValid, values }) =>

          <FormikForm>
            <Modal.Header closeButton>
              <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            
            <Modal.Body>

              <Form.Group>
                <Form.Label>Name</Form.Label>
                <Form.Control
                  name="name"
                  ref={nameRef}
                  isInvalid={!!errors.name}
                  value={values.name}
                  onChange={handleChange}
                />
                <Form.Control.Feedback type="invalid">{errors.name}</Form.Control.Feedback>
              </Form.Group>

            </Modal.Body>

            <Modal.Footer>
              <Button
                variant="light"
                onClick={() => onCancel()}
              >
                Cancel
              </Button>

              <Button
                className="d-flex align-items-center"
                variant="primary"
                type="submit"
                disabled={!isValid || isSubmitting}
              >
                Save {isSubmitting && <Spinner animation="border" size="sm" className="ml-2" />}
              </Button>

            </Modal.Footer>
          </FormikForm>
        }
      </Formik>
    </Modal>
  );
};

CategoryNew.propTypes = {
  id: PropTypes.string,
  category: PropTypes.object.isRequired,
  show: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  working: PropTypes.bool.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  existingKeys: PropTypes.object,
};

export default CategoryNew;