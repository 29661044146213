import React from 'react';
import { Alert, Button, Spinner } from 'react-bootstrap';
import { compose } from 'recompose';
import { withAuth, withFirebase } from '../common/providers';
import { LogoCardContainer } from '../common/components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';

const ACTION_RESET_PASSWORD = 'resetpassword';
const ACTION_VERIFY_EMAIL = 'verifyemail';

const Verify = ({
  history,
  location,
  firebase,
  user,
}) => {
  const params = new URLSearchParams(location.search);
  const action = params.get('mode') || ''; 
  const code = params.get('oobCode');

  const [loading, setLoading] = React.useState(true);
  const [ error, setError ] = React.useState();

  React.useState(() => {
    const verify = async () => {
      try {
        switch (action.toLowerCase()) {
          case ACTION_RESET_PASSWORD:
            history.push({ ...location, pathname: '/account/changepassword' });
            return;

          case ACTION_VERIFY_EMAIL:
            await firebase.verifyEmail(code);
            break;

          default:
            setError('Unsupported verification')

        }

      } catch (err) {
        setError(err.message);
      }

      setLoading(false);

    };

    verify();

  }, [code]);

  return (
    <LogoCardContainer title="Verification">

      {loading && 
        <div className="text-center">
          <Spinner animation="border" variant="primary" />
        </div>
      }

      {!loading &&
        <>
          <Alert variant={error ? 'danger' : 'success' }>
            <FontAwesomeIcon icon={faCheckCircle} />
            <span className="ml-2">
              {error && error}
              {!error && 'Email verified'}
            </span>
          </Alert>

          <Button
            block
            variant="outline-primary"
            href={user ? '/account' : '/account/signin'}
          >
            {user && 'My Account'}
            {!user && 'Sign In'}
          </Button>
        </>
      }

    </LogoCardContainer>
  );
};

export default compose(
  withAuth,
  withFirebase,
)(Verify);