import React from 'react';
import SignInTemplate from './SignInTemplate';
import { Toast } from '../common/components';
import { withFirebase } from '../common/providers';
import { FIREBASE_ERRORS, ERROR_GENERIC } from '../common/Constants';

const SignUp = ({
  firebase,
  history,
  location,
}) => {
  const params = new URLSearchParams(location.search);
  const invitationId = params.get('invitationId');

  const handleEmailSignIn = async ({ email, password }) => {
    try {
      const invitation = await firebase.getInvitation(invitationId);
      if (!invitation) {
        Toast({ error: 'Invalid invitation!' });
        return;
      }

      if (invitation.acceptedAt) {
        Toast({ error: 'Invitation already accepted!' });
        return;
      }

      await firebase.signUpWithEmailAndPassword(email, password);
    } catch (err) {
      console.error(err);
      const error = FIREBASE_ERRORS[err.code] || ERROR_GENERIC;
      Toast({ error });
    }
  };

  const handleProviderSignIn = (provider) => {
    firebase.logEvent('login', { method: provider });

    // Google sign in (only)
    firebase.signIn();
  };

  const handleVerifyInvitation = async (values) => {
    const { invitationId } = values;

    try {

      const invitation = await firebase.getInvitation(invitationId.toLowerCase());
      if (!invitation) {
        Toast({ error: 'Invalid code!' });
        return;
      }

      if (invitation.acceptedAt) {
        Toast({ error: 'Invitation already accepted!' });
        return;
      }

      // Check invitation code
      history.push(`${location.pathname}?invitationId=${invitationId}`);

    } catch (err) {
      firebase.logError(err);
    }
  };

  return (
    <SignInTemplate
      onEmailPasswordSignIn={handleEmailSignIn}
      onProviderSignIn={handleProviderSignIn}
      onVerifyInvitation={handleVerifyInvitation}
      isSignIn={false}
    />
  );
};

export default withFirebase(SignUp);