import React from 'react';
import { LATEST_VERSION } from '../Constants';
import { withFirebase } from './firebase';

const AuthContext = React.createContext();

const AuthProvider = ({ children, firebase }) => {
  const [loading, setLoading] = React.useState(true);
  const [user, setUser] = React.useState();

  React.useEffect(() => {
    const tryMigrate = async (authUser) => {
      if (authUser) {
        const { store, storeId, version } = await firebase.getUserClaims();

        const roles = await firebase.getUserRoles(authUser.uid);

        setUser({
          store,
          storeId,
          version,
          id: authUser.uid,
          name: authUser.displayName || authUser.email,
          email: authUser.email,
          emailVerified: authUser.emailVerified,
          providerId: authUser.providerData[0].providerId,
          image: authUser.photoURL,
          roles: roles.map(r => r.id),
        });

        // Use store to determine if this is not a first time user
        if (store && version !== LATEST_VERSION) {
          const result = await firebase.tryMigrate();

          await firebase.setClaims({ version: result.data.version });
          await firebase.refreshToken();
        }
      } else {
        setUser(null);
      }

      setLoading(false);
    };

    const unsubscribe = firebase.auth.onAuthStateChanged(authUser => {
      authUser && firebase.analytics.setUserId(authUser.uid);

      tryMigrate(authUser);

    });

    return () => {
      unsubscribe();
    };
  }, [firebase]);

  const obj = {
    isUserLoading: loading,
    user,
  };

  return (
    <AuthContext.Provider value={obj}>
      {children}
    </AuthContext.Provider>
  );
};

const withAuth = Component => props => (
  <AuthContext.Consumer>
    {value => <Component {...props} {...value} />}
  </AuthContext.Consumer>
);

export default withFirebase(AuthProvider);
export {
  AuthContext,
  withAuth,
};