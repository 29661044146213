import React from 'react';
import { createNameKeys } from '../helpers';

export const menuDelete = (state, action) => ({
  ...state,
  deletePrompt: {
    show: true,
    title: 'Delete Menu',
    content: (
      <>
        <p>Are you sure you want to permanently delete <strong>{state.menu.name}</strong>?</p>
        <p>You keep:</p>
        <ul>
          <li>Products</li>
        </ul>
      </>
    ),
    handler: action.handler,
    args: {}
  }
});

export const menuDeleteCancel = (state) => ({
  ...state,
  menuDelete: {
    ...state.menuDelete,
    prompt: false,
  },
});

export const menuEdit = (state) => ({
  ...state,
  menuEdit: {
    ...state.menuEdit,
    editing: true,
  },
});

export const menuEditCancel = (state) => ({
  ...state,
  menuEdit: {
    ...state.menuEdit,
    editing: false,
  },
});

export const menuLoad = (state, action) => {
  const { menu } = action;
  let categoryKeys = {};
  let productKeys = {};

  if (menu) {
    categoryKeys = createNameKeys(menu.categories);
    productKeys = createNameKeys(menu.products);
  }

  // Show steps for first time menu creation
  const showSteps = menu &&
    menu.createdAt &&
    menu.updatedAt && 
    !menu.categoryOrder.length &&
    menu.createdAt.toDate().toISOString() === menu.updatedAt.toDate().toISOString();

  return {
    ...state,
    menu,
    categoryKeys,
    productKeys,
    showSteps,
  };
};

export const menuUpdate = (state, action) => {
  const { menu } = action;
  const categoryKeys = createNameKeys(menu.categories);
  const productKeys = createNameKeys(menu.products);

  return {
    ...state,
    categoryKeys,
    productKeys,
    loading: false,
    working: false,
    deletePrompt: {
      show: false,
      title: '',
      content: null,
      handler: null,
      args: {},
    },
    menu,
    menuEdit: {
      editing: false,
    },
    categoryEdit: {
      id: null,
      index: -1,
      category: {},
      editing: false,
    },
    productEdit: {
      id: null,
      index: -1,
      catId: null,
      product: {},
    },
    showSteps: false,
  };
};