import React from 'react';
import { Container } from 'react-bootstrap';
import { LoadingModal, Toast } from '../common/components';
import { withFirebase } from '../common/providers';

import { Menu, MenuImport } from '../menus';

const InvitationEdit = ({
  firebase,
  match,
}) => {
  const [loading, setLoading] = React.useState(true);
  const [invitation, setInvitation] = React.useState();
  const [store, setStore] = React.useState();
  const [menu, setMenu] = React.useState();
  const { invitationId } = match.params;

  const handleMenuImported = (menu) => {
    Toast({ success: 'Menu imported!' });
    setMenu(menu);
  };

  React.useEffect(() => {
    const fetchData = async () => {
      const apiInvitation = await firebase.getInvitation(invitationId);

      const apiStore = await firebase.getStore(invitationId);

      // There should only be one
      const dayPart = await firebase.getDayPart(invitationId, 'default');
      const [firstMenuId] = dayPart.menuOrder || [];
      const apiMenu = dayPart.menus[firstMenuId];

      setInvitation(apiInvitation);
      setStore(apiStore);
      setMenu(apiMenu);
      setLoading(false);
    };

    fetchData();
  }, [firebase, invitationId]);

  if (loading) {
    return <LoadingModal />;
  }

  return (
    <Container className="mt-4">
      <h1>{invitation.name}'s Invitation</h1>
      <p>Public link: <a href={store.url}>{store.url}</a></p>

      {menu && <Menu menu={menu} />}

      {!menu && 
        <div className="mt-4">
          <h3>Menu Import</h3>

          <MenuImport
            storeId={invitationId}
            onMenuImported={handleMenuImported}
          />
        </div>
      }
    </Container>
  );
};

export default withFirebase(InvitationEdit);