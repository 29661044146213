import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGripLines, faPlus, faTimes } from '@fortawesome/free-solid-svg-icons';

import Product from './Product';
import './Category.scss';

const Category = ({
  id,
  index,
  category,
  productData,
  onCategoryDelete,
  onCategoryEdit,
  onProductCreate,
  onProductDelete,
  onProductEdit,
}) => {
  const { name, productOrder } = category;

  return (
    <Draggable draggableId={id} index={index}>
      {(provided, snapshot) =>
        <div
          className={`cat ${snapshot.isDragging ? 'mm-drag-item' : ''}`}
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          <div className="cat__header">
            <FontAwesomeIcon icon={faGripLines} className="cat__grip" />

            <h5 className="cat__title" onClick={() => onCategoryEdit(id)}>
              {name}
            </h5>

            <Button variant="default" size="sm" onClick={() => onCategoryDelete(id)}>
              <FontAwesomeIcon icon={faTimes} />
            </Button>

          </div>

          <Droppable droppableId={id} type="prod">
            {(provided, snapshot) =>

              <div
                className={snapshot.isDraggingOver ? 'mm-draggable-section': ''}
                ref={provided.innerRef}
                {...provided.droppableProps}
              >

                  {!!productOrder.length && productOrder.map((prodId, index) =>
                    <Product
                      id={prodId}
                      key={prodId}
                      index={index}
                      product={productData[prodId]}
                      onProductEdit={onProductEdit}
                      onProductDelete={(prodId) => onProductDelete(prodId, index)}
                    />
                  )}

                  {provided.placeholder}

                  <div className="prod__btn" onClick={() => onProductCreate(id)}>
                    <FontAwesomeIcon icon={faPlus} className="mr-2" /> New Product
                  </div>

              </div>
            }

          </Droppable>

        </div>
      }
    </Draggable>
  );
};

Category.propTypes = {
  id: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  category: PropTypes.object.isRequired,
  productData: PropTypes.object.isRequired,
  onCategoryDelete: PropTypes.func.isRequired,
  onCategoryEdit: PropTypes.func.isRequired,
  onProductCreate: PropTypes.func.isRequired,
  onProductDelete: PropTypes.func.isRequired,
  onProductEdit: PropTypes.func.isRequired,
};

export default Category;