import React from 'react';
import SignInTemplate from './SignInTemplate';
import { Toast } from '../common/components';
import { withFirebase } from '../common/providers';

const SignIn = ({ firebase }) => {
  const handleEmailSignIn = async ({ email, password }) => {
    try {
      await firebase.signInWithEmailAndPassword(email, password);
      firebase.logEvent('login', { method: 'email' });
    } catch {
      Toast({ error: 'Invalid credentials!' });
    }
  };

  const handleProviderSignIn = (provider) => {
    firebase.logEvent('login', { method: provider });

    // Google sign in (only)
    firebase.signIn();
  }

  return (
    <SignInTemplate
      onEmailPasswordSignIn={handleEmailSignIn}
      onProviderSignIn={handleProviderSignIn}
      isSignIn={true}
    />
  );
};

export default withFirebase(SignIn);