import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import * as Yup from 'yup';
import { Alert, Button, Col, Container, Form, Modal, Row, Spinner } from 'react-bootstrap';
import { Formik, Form as FormikForm } from 'formik';
import { LogoText } from '../common/components';
import { MenuAdd, MenusIcon, MenuView, NoPdfIcon, SavingsIcon, SimplifyIcon, SortingIcon, VerifiedIcon } from '../common/images';
import { Link, withRouter } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';

import './SignInTemplate.scss';

const PageContainer = styled.div`
  min-height: 100%;
  background: var(--green);
`;

const WhiteContainer = styled.div`
  background: #fff;
  position: relative;
`;

const GreenRow = styled.div`
  width: 100%;
  background: var(--green);
  height: 25px;
  position: absolute;
  left: 0;
  bottom: 0;
`;

const SignInContainer = styled.div`
  @media (min-width: 992px) {
    width: 400px;
    margin-left: auto;
    margin-right: 1.2rem;
  }
`;

const HeadingContainer = styled.div`
  @media (min-width: 992px) {
    margin-left: 1.2rem;
  }
`;

const Input = styled(Form.Control)`
  height: calc(1.5em + 1rem + 6px);
`;

const LoginButton = styled(Button)`
  padding-top: .7rem;
  padding-bottom: .7rem;
`;

const GreenContainer = styled.div`
  background: var(--green);
`;

const PromoIconBox = styled.div`
  height: 75px;
  width: 75px;
  padding: 15px;
  border-radius: 5px 25px;
  background: var(--tan);
`;

const SignInSchema = Yup.object().shape({
  email: Yup.string()
    .required('Email is required')
    .email('Invalid email'),
  password: Yup.string().required('Password is required'),
});

const SignUpSchema = Yup.object().shape({
  email: Yup.string()
    .required('Email is required')
    .email('Invalid email'),
  password: Yup.string()
    .required('Password is required')
    .min(8, 'Must be atleast 8 characters'),
});

const SignInTemplate = ({
  location,
  onEmailPasswordSignIn,
  onProviderSignIn,
  onVerifyInvitation,
  isSignIn,
}) => {
  const query = location.search;
  const urlParams = new URLSearchParams(query);
  const invitationId = urlParams.get('invitationId');
  const invitationIdRef = React.useRef();

  const getHeading = () => {
    return isSignIn ? (
      <>
        <h1 className="mb-0">Welcome back!</h1>
        <h1 className="mb-0">We missed you.</h1>
      </>
    ) : (
      <>
        <h1 className="mb-0">It's free.</h1>
        <h1 className="mb-0">Sign up today!</h1>
      </>
    );
  };

  const getRedirectCaption = () => isSignIn ? 'Have an invitation code?' : 'Already have an account?';

  const getRedirectLink = () => isSignIn ? `/account/signup${query}` : `/account/signin${query}`;

  const getRedirectLinkText = () => isSignIn ? 'Create an account' : 'Sign in';

  const renderPromo = ({ image, title, text }) => (
    <Col md={6} lg={6} xl={4} className="p-4 d-flex justify-content-start align-items-center">
      <PromoIconBox className="mr-3 d-flex justify-content-center align-items-center">
        <img src={image} alt="Multiple Menus" style={{ width: '100%'}} />
      </PromoIconBox>
      <div className="text-white">
        <h5 className="text-white mb-0">{title}</h5>
        <span>{text}</span>
      </div>
    </Col>
  );

  React.useEffect(() => {
    if (!isSignIn && !invitationId) {
      invitationIdRef.current.focus();
    }
  }, [invitationId, isSignIn]);

  return (
    <PageContainer>
      <WhiteContainer className="py-4">
        <div className="py-4 text-center">
          <a href="https://markermenu.com" className="text-decoration-none">
            <LogoText />
          </a>
        </div>
      </WhiteContainer>

      <WhiteContainer classsName="position-relative">

        <GreenRow className="d-none d-md-block d-lg-block d-xl-block" />

        <Container>

          {invitationId &&
            <Row>
              <Col md={6}>
                <SignInContainer>
                  <Alert variant="success">
                    <FontAwesomeIcon icon={faCheckCircle} className="mr-2" />
                    You have an invitation. Sign up to accept it!
                  </Alert>
                </SignInContainer>
              </Col>
            </Row>
          }

          <Row>
            <Col md={6}>
              <SignInContainer>

                <Formik
                  initialValues={{ email: '', password: '' }}
                  validationSchema={isSignIn ? SignInSchema : SignUpSchema}
                  onSubmit={onEmailPasswordSignIn}
                >
                  {({ errors, handleBlur, handleChange, isSubmitting, isValid, touched, values }) =>
                    <FormikForm className="mm-mb">
                      <Form.Group>
                        <Form.Label>Email</Form.Label>
                        <Input
                          name="email"
                          type="email"
                          autoComplete="username"
                          autoFocus={true}
                          isInvalid={!!errors.email && touched.email}
                          value={values.email}
                          onBlur={handleBlur}
                          onChange={handleChange}
                        />
                        <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
                      </Form.Group>

                      <Form.Group>
                        <Form.Label>Password</Form.Label>
                        <Input
                          name="password"
                          type="password"
                          autoComplete="current-password"
                          isInvalid={!!errors.password && touched.password}
                          value={values.password}
                          onBlur={handleBlur}
                          onChange={handleChange}
                        />
                        <Form.Control.Feedback type="invalid">{errors.password}</Form.Control.Feedback>
                      </Form.Group>

                      <LoginButton
                        block
                        className="mt-2 d-flex justify-content-center align-items-center"
                        type="submit"
                        variant="primary"
                        disabled={!isValid || isSubmitting}
                      >
                        {isSignIn ? 'Sign In' : 'Create an Account'}
                        {isSubmitting && <Spinner animation="border" size="sm" className="ml-2" />}
                      </LoginButton>
                    </FormikForm>
                  }
                </Formik>

                <div className="text-center mb-4">
                  <p className="mb-4">
                    {getRedirectCaption()} <Link to={getRedirectLink}><strong>{getRedirectLinkText()}</strong></Link>
                  </p>
                  <Link to="/account/passwordreset"><strong>Forgot password?</strong></Link>
                </div>

              </SignInContainer>
            </Col>

            <Col md={6} className="d-none d-md-block d-lg-block d-xl-block">
              <HeadingContainer className="mt-4">
                
                <div className="mb-4">
                  {getHeading()}
                </div>

                <img src={isSignIn ? MenuView : MenuAdd} alt="View Menu" />
              </HeadingContainer>
            </Col>
          </Row>

        </Container>

      </WhiteContainer>

      <GreenContainer>
        <Container className="py-4">
          <Row>
            {renderPromo({ image: MenusIcon, title: 'Create Multiple Menus', text: 'Create one or more menu of any size' })}
            {renderPromo({ image: SortingIcon, title: 'Sorting', text: 'Easy drag and drop to rearrange your menu' })}
            {renderPromo({ image: SimplifyIcon, title: 'Simplified Updates', text: 'Menus update within seconds after updating product' })}
            {renderPromo({ image: VerifiedIcon, title: 'Verified', text: 'Assure trust with a verified badge' })}
            {renderPromo({ image: NoPdfIcon, title: 'No PDF', text: 'View your menu in a clear and concise way' })}
            {renderPromo({ image: SavingsIcon, title: 'Savings', text: 'Save time and money on printing and cleaning menus' })}
          </Row>
        </Container>

        <p className="mb-0 py-3 text-center text-white">Copyright © {new Date().getFullYear()} Lean Group Inc.</p>
      </GreenContainer>

      <Modal show={!isSignIn && !invitationId} backdrop="static">
        <Modal.Body>
          <h3 className="text-center mb-4">Invitation Code</h3>

          <Formik
            initialValues={{ invitationId: '' }}
            validate={values => values.invitationId ? {} : { invitationId: 'Invitation code is required'}}
            onSubmit={onVerifyInvitation}
          >
            {({ errors, handleChange, isSubmitting, isValid, values }) =>
              <FormikForm>
                <Form.Group>
                  <Form.Control
                    name="invitationId"
                    className="text-center text-uppercase"
                    size="lg"
                    ref={invitationIdRef}
                    isInvalid={!!errors.invitationId}
                    style={{ letterSpacing: 3 }}
                    value={values.invitationId}
                    onChange={handleChange}
                  />
                </Form.Group>

                <Button
                  block
                  className="mb-2 d-flex justify-content-center align-items-center"
                  type="submit"
                  disabled={!isValid || isSubmitting}
                >
                    Sign Me Up {isSubmitting && <Spinner animation="border" size="sm" className="ml-2" />}
                </Button>

                <p className="text-center">
                  <Link to="/account/signin">Sign In</Link>
                </p>

              </FormikForm>
            }
          </Formik>

        </Modal.Body>
      </Modal>

    </PageContainer>
  );
};

SignInTemplate.propTypes = {
  onEmailPasswordSignIn: PropTypes.func.isRequired,
  onProviderSignIn: PropTypes.func.isRequired,
  onVerifyInvitation: PropTypes.func,
  isSignIn: PropTypes.bool.isRequired,
};

export default withRouter(SignInTemplate);