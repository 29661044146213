import React from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { Button, Form, Modal, Spinner } from 'react-bootstrap';
import { Formik, Form as FormikForm } from 'formik';
import { ModalOverlay } from '../common/components';

const MenuSettingsSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
});

const MenuSettings = ({
  menu,
  show,
  working,
  onConfirm,
  onCancel,
  onDelete,
}) => {
  const [showDelete, setShowDelete] = React.useState(false);
  const nameRef = React.useRef();

  return (
    <Modal
      show={show}
      onHide={() => onCancel()}
      onEntered={() => nameRef.current.focus()}
    >
      <Formik
        initialValues={menu}
        enableReinitialize={true}
        validationSchema={MenuSettingsSchema}
        onSubmit={onConfirm}
      >
        {({ errors, handleChange, isValid, values }) =>
          <FormikForm>

            <ModalOverlay
              show={showDelete}
              title="Delete Menu"
              description={<span>Are you sure you want to delete <strong>{menu.name}</strong>?</span>}
              working={working}
              onConfirm={() => onDelete()}
              onCancel={() => setShowDelete(false)}
            />

            <Modal.Header closeButton>
              <Modal.Title>Menu Settings</Modal.Title>
            </Modal.Header>

            <Modal.Body>

              <Form.Group>
                <Form.Label>Name</Form.Label>
                <Form.Control
                  name="name"
                  ref={nameRef}
                  isInvalid={!!errors.name}
                  value={values.name}
                  onChange={handleChange}
                />
                <Form.Control.Feedback type="invalid">{errors.name}</Form.Control.Feedback>
              </Form.Group>

            </Modal.Body>

            <Modal.Footer className="d-flex justify-content-between">
              <Button
                variant="outline-danger"
                onClick={() => setShowDelete(true)}
              >
                Delete
              </Button>

              <div className="d-flex flex-row">
                <Button
                  variant="light"
                  onClick={() => onCancel()}
                >
                  Cancel
                </Button>

                <Button
                  className="d-flex align-items-center ml-2"
                  type="submit"
                  variant="primary"
                  disabled={!isValid}
                >
                  Done {!showDelete && working && <Spinner animation="border" size="sm" className="ml-2" />}
                </Button>
              </div>

            </Modal.Footer>

          </FormikForm>
        }
      </Formik>
    </Modal>
  );
};

MenuSettings.propTypes = {
  menu: PropTypes.object.isRequired,
  show: PropTypes.bool.isRequired,
  working: PropTypes.bool.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default MenuSettings;