import React from 'react';
import { createNameKeys, deepClone } from '../helpers';

const CategoryDefault = {
  name: '',
  productOrder: [],
};

export const categoryCreate = (state) => ({
  ...state,
  categoryEdit: {
    ...state.categoryEdit,
    id: null,
    category: deepClone(CategoryDefault),
    editing: true,
  },
});

export const categoryDelete = (state, action) => {
  const { id: catId, index, handler } = action;
  const { menu } = state;

  const name = menu.categories[catId].name;

  return {
    ...state,
    deletePrompt: {
      handler,
      show: true,
      args: { catId, index },
      title: `Delete Category`,
      content: (
        <>
          <p>Are you sure you want to delete <strong>{name}</strong>?</p>
          <p>You keep:</p>
          <ul>
            <li>Products</li>
          </ul>
        </>
      )
    }
  };
};

export const categoryEdit = (state, action) => {
  const { id, index, category } = action;

  return {
    ...state,
    categoryEdit: {
      ...state.categoryEdit,
      id,
      index,
      category: { ...category },
      editing: true,
    },
  }
};

export const categoryUpdate = (state, action) => {
  const { id: newId, category: newCategory } = action;
  const { menu, categoryEdit } = state;
  const { id: oldId } = categoryEdit;

  const categoryOrder = menu.categoryOrder.slice();

  // New category
  if (!oldId) {

    // Push the id generated from edit view
    categoryOrder.push(newId);
  }

  const newCategories = {
    ...state.menu.categories,
    [newId]: newCategory,
  };

  // Create category keys to check for duplicates
  // on the next category
  const categoryKeys = createNameKeys(newCategories);

  return {
    ...state,
    categoryKeys,
    menu: {
      ...state.menu,
      categoryOrder,
      categories: newCategories,
    },
    categoryEdit: {
      ...state.categoryEdit,
      id: null,
      index: -1,
      category: {},
      editing: false,
    },
  };
};

export const categoryEditCancel = (state, action) => ({
  ...state,
  categoryEdit: {
    ...state.categoryEdit,
    id: null,
    index: -1,
    category: {},
    editing: false,
  },
});