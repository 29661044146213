import React from 'react';
import PropTypes from 'prop-types';
import ReactPaginate from 'react-paginate';
import { faAngleDoubleRight, faAngleDoubleLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Pagination = ({
  maxItems,
  limit,
  marginPagesDisplayed = 2,
  pageRangeDisplayed = 5,
  onPageChange,
}) => {

  if (maxItems <= limit) {
    return null;
  }

  return (
    <ReactPaginate
      activeClassName="active"
      containerClassName="pagination justify-content-center"
      marginPagesDisplayed={marginPagesDisplayed}
      onPageChange={onPageChange}
      pageCount={Math.ceil(maxItems / limit)}
      pageRangeDisplayed={pageRangeDisplayed}
      pageClassName="page-item"
      pageLinkClassName="page-link"
      breakClassName="page-item"
      breakLinkClassName="page-link"
      previousClassName="page-item"
      previousLinkClassName="page-link"
      previousLabel={<FontAwesomeIcon icon={faAngleDoubleLeft} />}
      nextClassName="page-item"
      nextLinkClassName="page-link"
      nextLabel={<FontAwesomeIcon icon={faAngleDoubleRight} />}
    />
  );
};

Pagination.propTypes = {
  maxItems: PropTypes.number.isRequired,
  limit: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  marginPagesDisplayed: PropTypes.number,
  pageRangeDisplayed: PropTypes.number,
};

export default Pagination;