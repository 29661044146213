import * as Yup from 'yup';

export default Yup.object().shape({
  name: Yup.string()
    .required('Name is required')
    .max(50, 'Max 50 characters'),
  description: Yup.string()
    .required('Description is required'),
  price: Yup.number()
    .required('Price is required')
    .min(0, 'Minimum price must be $0'),
  image: Yup.string(),
});