import React from 'react';
import styled from 'styled-components';
import { Spinner } from 'react-bootstrap';
import { compose } from 'recompose';
import { withAuth, withFirebase } from '../common/providers';
import { Toast } from '../common/components';

import Menu from './Menu';

const LoadingSpinner = styled(Spinner)`
  color: ${props => props.theme.color};
`;

const MenuPublic = ({
  firebase,
  history,
  match,
}) => {
  const [loading, setLoading] = React.useState(true);
  const [menu, setMenu] = React.useState();
  const { storeId, dayPartId, menuId } = match.params;

  React.useEffect(() => {
    const fetchMenu = async () => {
      try {
        const apiMenu = await firebase.getStoreMenu(storeId, menuId);

        if (!apiMenu) {
          history.push(`/stores/${storeId}`);
          return;
        }

        firebase.logEvent('view_item_list', {
          item_list_id: menuId,
          item_list_name: apiMenu.name,
          store_id: storeId,
          daypart_id: dayPartId,
        });

        setMenu(apiMenu);
        setLoading(false);
      } catch (err) {
        firebase.logError(err);
        Toast({ error: err.message });
      }
    };

    fetchMenu();
  }, [firebase, history, storeId, dayPartId, menuId]);

  if (loading) {
    return (
      <div className="text-center my-2">
        <LoadingSpinner animation="border" /> 
      </div>
    );
  }

  return <Menu menu={menu} />;
};

export default compose(
  withAuth,
  withFirebase,
)(MenuPublic);