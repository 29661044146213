import React from 'react';
import * as Yup from 'yup';
import { Button, Form, Spinner } from 'react-bootstrap';
import { Formik, Form as FormikForm } from 'formik';
import { Redirect } from 'react-router-dom';
import { LoadingModal, LogoCardContainer, Toast } from '../common/components';
import { withFirebase } from '../common/providers';

const ChangePasswordSchema = Yup.object().shape({
  password: Yup.string()
    .required('Password is required')
    .min(8, 'Password must be at least 8 characters'),
  confirm: Yup.string()
    .required('Confirm password is required')
    .oneOf([Yup.ref('password')], 'Password does not match'),
});

const ChangePassword = ({
  location,
  firebase,
}) => {
  const params = new URLSearchParams(location.search);

  const [loading, setLoading] = React.useState(true)
  const [code, setCode] = React.useState(params.get('oobCode'));

  const handleSubmit = async (values) => {
    const { password } = values;

    try {
      const email = await firebase.verifyPasswordResetCode(code);
      
      await firebase.changePassword(code, password);

      Toast({ success: 'Password updated!' });

      firebase.signInWithEmailAndPassword(email, password);
    } catch (err) {
      firebase.logError(err);
      Toast({ error: err.message });
    }
  };

  React.useEffect(() => {
    const verifyCode = async () => {
      if (code) {
        try {
          await firebase.verifyPasswordResetCode(code);
        } catch (err) {
          Toast({ error: err.message });
          setCode(null);
        }
      }

      setLoading(false);
    };

    verifyCode();

  }, [code, firebase]);

  if (loading) {
    return <LoadingModal />;
  }

  if (!code) {
    return <Redirect to="/" />
  }

  return (
    <LogoCardContainer
      title="Change Password"
      description="Enter your new password"
    >
      <Formik
        initialValues={{ password: '', confirm: '' }}
        validationSchema={ChangePasswordSchema}
        onSubmit={handleSubmit}
      >
        {({ errors, handleChange, isSubmitting, isValid, values }) =>

          <FormikForm>

            <Form.Group>
              <Form.Label>Password</Form.Label>
              <Form.Control
                name="password"
                type="password"
                autoFocus={true}
                isInvalid={!!errors.password}
                value={values.password}
                onChange={handleChange}
              />
              <Form.Control.Feedback type="invalid">{errors.password}</Form.Control.Feedback>
            </Form.Group>

            <Form.Group>
              <Form.Label>Confirm Password</Form.Label>
              <Form.Control
                name="confirm"
                type="password"
                isInvalid={!!errors.confirm}
                value={values.confirm}
                onChange={handleChange}
              />
              <Form.Control.Feedback type="invalid">{errors.confirm}</Form.Control.Feedback>
            </Form.Group>

            <Button
              block
              className="d-flex justify-content-center align-items-center"
              type="submit"
              disabled={!isValid || isSubmitting}
            >
              Change Password {isSubmitting && <Spinner className="ml-2" animation="border" size="sm" />}
            </Button>

          </FormikForm>

        }

      </Formik>
    </LogoCardContainer>
  );
};

export default withFirebase(ChangePassword);