import React from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { Button, Form, InputGroup, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Formik, Form as FormikForm } from 'formik';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faGlobe, faInfoCircle, faEnvelope } from '@fortawesome/free-solid-svg-icons';

const StoreSchema = Yup.object().shape({
  phone: Yup.string()
    .required('Phone is required')
    .length(10, 'Phone must be 10 digits with area code'),
  web: Yup.string()
    .url('Invalid url'),
  supportEmail: Yup.string()
    .email('Invalid email'),
});

const SetupStoreInfo = ({
  contact = { phone: '', web: '', supportEmail: '' },
  confirmLabel = 'Save',
  onConfirm,
}) => {

  return (
    <Formik
      initialValues={contact}
      validationSchema={StoreSchema}
      onSubmit={values => onConfirm(values)}
    >

      {({ errors, handleChange, isValid, values }) =>
        <FormikForm>

          <Form.Group>
            <Form.Group>
              <Form.Label>Phone</Form.Label>
              <InputGroup>
                <InputGroup.Prepend>
                  <InputGroup.Text>
                    <FontAwesomeIcon icon={faPhone} />
                  </InputGroup.Text>
                </InputGroup.Prepend>

                <Form.Control
                  name="phone"
                  type="number"
                  isInvalid={!!errors.phone}
                  value={values.phone}
                  onChange={handleChange}
                />
                <Form.Control.Feedback type="invalid">{errors.phone}</Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
          </Form.Group>

          <Form.Group>
            <Form.Group>
              <Form.Label>Web</Form.Label>
              <InputGroup>
                <InputGroup.Prepend>
                  <InputGroup.Text>
                    <FontAwesomeIcon icon={faGlobe} />
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <Form.Control
                  name="web"
                  isInvalid={!!errors.web}
                  value={values.web}
                  onChange={handleChange}
                />
                <Form.Control.Feedback type="invalid">{errors.web}</Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
          </Form.Group>

          <Form.Group>
            <Form.Group>
              <Form.Label>
                Support Email&nbsp;
                <OverlayTrigger
                  placement="right"
                  overlay={<Tooltip>Contact email for guests which may be different from the sign in email.</Tooltip>}
                >
                  <FontAwesomeIcon icon={faInfoCircle} />
                </OverlayTrigger>
              </Form.Label>

              <InputGroup>
                <InputGroup.Prepend>
                  <InputGroup.Text>
                    <FontAwesomeIcon icon={faEnvelope} />
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <Form.Control
                  name="supportEmail"
                  isInvalid={!!errors.supportEmail}
                  value={values.supportEmail}
                  onChange={handleChange}
                />
                <Form.Control.Feedback type="invalid">{errors.supportEmail}</Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
          </Form.Group>

          <div className="text-right">
            <Button type="submit" disabled={!isValid}>{confirmLabel}</Button>
          </div>

        </FormikForm>
      }

    </Formik>
  );
};

SetupStoreInfo.propTypes = {
  onConfirm: PropTypes.func.isRequired,
  confirmLabel: PropTypes.string,
};

export default SetupStoreInfo;