import React from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { Col, Collapse, Button, Form, Row } from 'react-bootstrap';
import { Formik, Form as FormikForm } from 'formik';

const ContactSchema = Yup.object().shape({
  web: Yup.string().url('Invalid url (ex: https://mystore.com)'),
  phone: Yup.string().length(10, 'Must be 10 digits'),
  supportEmail: Yup.string().email('Invalid email'),
});

const StoreContact = ({
  store,
  editing,
  onEditing,
  onConfirm,
}) => (
  <section className="section">
    <Row>
      <Col lg={4} className="section__title">
        <h5>Contact Info</h5>
      </Col>

      {!editing &&
        <>
          <Col lg={6} className="section__desc">
            Update phone, web, email
          </Col>

          <Col lg={2} className="section__edit">
            <Button variant="outline-primary" size="sm" onClick={() => onEditing()}>
              Edit
            </Button>
          </Col>
        </>
      }
    </Row>

    <Collapse in={editing}>
      <div>

        <Formik
          initialValues={{ ...store }}
          validationSchema={ContactSchema}
          onSubmit={onConfirm}
        >
          {({ errors, handleChange, isValid, values }) =>
            <FormikForm>

              <Form.Group as={Row}>
                <Form.Label column lg={4}>Phone (optional)</Form.Label>
                <Col lg={8}>
                  <Form.Control
                    name="phone"
                    isInvalid={!!errors.phone}
                    value={values.phone}
                    onChange={handleChange}
                  />
                  <Form.Control.Feedback type="invalid">{errors.phone}</Form.Control.Feedback>
                </Col>
              </Form.Group>

              <Form.Group as={Row}>
                <Form.Label column lg={4}>Web Site (optional)</Form.Label>
                <Col lg={8}>
                  <Form.Control
                    name="web"
                    type="url"
                    isInvalid={!!errors.web}
                    value={values.web}
                    onChange={handleChange}
                  />
                  <Form.Control.Feedback type="invalid">{errors.web}</Form.Control.Feedback>
                </Col>
              </Form.Group>

              <Form.Group as={Row}>
                <Form.Label column lg={4}>Support Email (optional)</Form.Label>
                <Col lg={8}>
                  <Form.Control
                    name="supportEmail"
                    isInvalid={!!errors.supportEmail}
                    value={values.supportEmail}
                    onChange={handleChange}
                  />
                  <Form.Control.Feedback type="invalid">{errors.supportEmail}</Form.Control.Feedback>
                </Col>
              </Form.Group>

              <Row>
                <Col lg={{ span: 8, offset: 4 }}>
                  <Button type="submit" disabled={!isValid}>Save</Button>
                </Col>
              </Row>

            </FormikForm>
          }
        </Formik>

      </div>
    </Collapse>
  </section>
);

StoreContact.propTypes = {
  store: PropTypes.object.isRequired,
  editing: PropTypes.bool.isRequired,
  onEditing: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};

export default StoreContact;