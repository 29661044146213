import React from 'react';
import { Alert, Carousel, Modal } from 'react-bootstrap';
import { compose } from 'recompose';
import { LoadingModal, Toast } from '../common/components';
import { ERROR_GENERIC, LATEST_VERSION } from '../common/Constants';
import { withAuth, withFirebase } from '../common/providers';

import SetupAddress from './SetupAddress';
import SetupStoreInfo from './SetupStoreInfo';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faExclamationTriangle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';

const DefaultStore = {
  name: '',
  phone: '',
  web: '',
  supportEmail: '',
  verified: false,
  productCount: 0,
  address: '',
  address2: '',
  city: '',
  state: '',
  zip: '',
};

const Setup = ({
  firebase,
  location,
  user,
}) => {
  const [loading, setLoading] = React.useState(true);
  const [index, setIndex] = React.useState(0);
  const [focusIndex, setFocusIndex] = React.useState(0);
  const [account, setAccount] = React.useState({});
  const [working, setWorking] = React.useState(false);
  const [invitation, setInvitation] = React.useState();
  const { id: userId, providerId } = user;
  const accepted = invitation && !!invitation.acceptedAt;

  const urlParams = new URLSearchParams(location.search)
  const invitationId = urlParams.get('invitationId');

  const handleStoreConfirm = (storeInfo) => {
    setAccount({ ...account, ...storeInfo });
    setIndex(1);
  }

  const handleAddressConfirm = async (addressInfo) => {
    const newAccount = { ...account, ...addressInfo };
    const newUser = {
      providerId,
      name: user.name || '',
      email: user.email,
      image: user.image || '',
    };

    // Use invitationId as storeId if valid
    let storeId = !accepted ? invitationId : null;

    setAccount(newAccount);
    setWorking(true);

    try {
      await firebase.createUser(userId, newUser);

      if (!storeId) {
        // Create a new store
        const store = await firebase.createStore(userId, newAccount);
        storeId = store.id;
      } else {
        // Update the store created by invitation and mark invitation as accepted
        await firebase.updateStore(storeId, newAccount);
        await firebase.acceptStoreInvitation(userId, storeId, invitationId, newAccount);
      }

      await firebase.setClaims({ storeId, store: newAccount.name, version: LATEST_VERSION });

      firebase.logEvent('sign_up');

      await firebase.refreshToken();

      // There's a race condition (from QA) when setting claims
      // the refreshToken doesn't always receive the latest.
      setTimeout(async () => {
        window.location.href = '/account';
      }, 1500);
    } catch (err) {
      firebase.logError(err);
      Toast({ error: ERROR_GENERIC })
    }
  }

  const handleSlid = () => setFocusIndex(index);

  React.useEffect(() => {
    const fetchStore = async () => {
      const curStore = { ...DefaultStore };

      if (invitationId) {
        try {
          const invitation = await firebase.getInvitation(invitationId);
          const apiStore = await firebase.getStore(invitationId);

          // Set invitation to show user valid/invalid message
          setInvitation(invitation);

          // Only assign store info if invitation hasn't been accepted
          if (invitation && !invitation.acceptedAt) {
            Object.assign(curStore, apiStore);
          }
        } catch (err) {
          firebase.logError(err);
        }
      }

      setAccount(curStore);
      setLoading(false);
    };

    fetchStore();
  }, [firebase, invitationId]);

  if (loading) {
    return <LoadingModal />;
  }

  return (
    <Modal show={true} backdrop="static" centered={true} size="lg">
      <Modal.Body className="p-4">
        <div className="mx-2 mb-4">
          <h3>Account</h3>
          <p>Tell us about your store</p>

          {invitationId &&
            <Alert variant={accepted || !invitation ? 'warning' : 'success'}>
              {!invitation &&
                <>
                  <FontAwesomeIcon icon={faTimesCircle} /> Invalid invitation! You can still create an account by completing this form.
                </>
              }

              {invitation && invitation.acceptedAt &&
                <>
                  <FontAwesomeIcon icon={faExclamationTriangle} /> This invitation was accepted on {invitation.acceptedAt.toDate().toLocaleString()}.
                  If you were expecting a valid invitation, contact <a href="mailto:support@markermenu.com">support@markermenu</a>.
                  If not, complete this form to create a new account.
                </>
              }

              {invitation && !invitation.acceptedAt &&
                <>
                  <FontAwesomeIcon icon={faCheckCircle} /> Thank for accepting our invitation for {invitation.name}. Review and complete this form to get started!
                </>
              }
            </Alert>
          }
        </div>

        <Carousel
          activeIndex={index}
          controls={false}
          indicators={false}
          onSelect={() => {}}
          onSlid={handleSlid}
        >
          <Carousel.Item>
            <div className="mx-2">
              <SetupStoreInfo 
                autoFocus={focusIndex === 0}
                store={{ ...account }}
                onConfirm={handleStoreConfirm}
              />
            </div>
          </Carousel.Item>

          <Carousel.Item>
            <div className="mx-2">
              <SetupAddress
                autoFocus={focusIndex === 1}
                address={{ ...account }}
                working={working}
                confirmLabel="Done"
                onConfirm={handleAddressConfirm}
                onBack={() => setIndex(0)}
              />
            </div>
          </Carousel.Item>
        </Carousel>

      </Modal.Body>
    </Modal>
  );
};

export default compose(
  withAuth,
  withFirebase,
)(Setup);