import app from 'firebase/app';
import config from './config';
import { customAlphabet } from 'nanoid';

import 'firebase/analytics';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';
import 'firebase/storage';

export const DEFAULT_DAY_PART_ID = 'default';

const nanoid = customAlphabet('0123456789abcdefghijklmnopqrstuvwxyz', 8);

export default class Firebase {

  constructor() {
    app.initializeApp(config);

    this.analytics = app.analytics();
    this.auth = app.auth();
    this.db = app.firestore();
    this.functions = app.functions();
    this.storage = app.storage();
  }

  /**
   * Marks the invitation as accepted and lists the store under user.
   * @param {string} userId User identifier.
   * @param {string} storeId Store identifier.
   * @param {string} invitationId Invitation identifier.
   * @param {object} store Store object.
   */
  acceptStoreInvitation = (userId, storeId, invitationId, store) => {
    const newInvitation = {
      acceptedAt: app.firestore.FieldValue.serverTimestamp(),
      updatedAt: app.firestore.FieldValue.serverTimestamp(),
    };

    const newStore = { name: store.name };

    const batch = this.db.batch();

    const invitationRef = this.getInvitationsRef().doc(invitationId);
    batch.update(invitationRef, newInvitation);

    const userStoreRef = this.getUserStoresRef(userId).doc(storeId);
    batch.set(userStoreRef, newStore);

    return batch.commit();
  };

  changePassword = (code, newPassword) => this.auth.confirmPasswordReset(code, newPassword);

  /**
   * Creates invitation and store using the invitation identifier.
   * @param {object} invitation Invitation object.
   */
  createInvitation = async (invitation) => {
    const newInvitation = {
      ...invitation,
      createdAt: app.firestore.FieldValue.serverTimestamp(),
      updatedAt: app.firestore.FieldValue.serverTimestamp(),
    };

    const newStore = {
      name: invitation.name,
      createdAt: app.firestore.FieldValue.serverTimestamp(),
      updatedAt: app.firestore.FieldValue.serverTimestamp(),
    };

    // Daypart
    const newDayPart = this.createDefaultDayPartObj();

    const batch = this.db.batch();

    const invitationRef = this.getInvitationsRef().doc(nanoid());
    batch.set(invitationRef, newInvitation);

    // Use invitationId as storeId
    const invitationId = invitationRef.id;

    const storeRef = this.getStoresRef().doc(invitationId);
    batch.set(storeRef, newStore);

    const dayPartRef = this.getDayPartsRef(invitationId).doc(DEFAULT_DAY_PART_ID);
    batch.set(dayPartRef, newDayPart);

    await batch.commit();

    return {
      ...newInvitation,
      id: invitationRef.id,
    };
  };

  createMenu = async (storeId, menu, dayPartId = DEFAULT_DAY_PART_ID) => {
    const newMenu = {
      ...menu,
      createdAt: app.firestore.FieldValue.serverTimestamp(),
      updatedAt: app.firestore.FieldValue.serverTimestamp(),
    };

    const newMenuSummary = {
      name: newMenu.name,
      active: newMenu.active,
      createdAt: newMenu.createdAt,
      updatedAt: newMenu.updatedAt,
    };

    const batch = this.db.batch();

    const storeMenuRef = this.getStoreMenusRef(storeId).doc();
    batch.set(storeMenuRef, newMenu);

    const menuId = storeMenuRef.id;

    // Get day part to update menu ref
    const dayPart = await this.getDayPart(storeId, dayPartId);
    const newDayPart = {
      ...dayPart,
      menus: {
        ...dayPart.menus,
        [menuId]: newMenuSummary,
      },
      menuOrder: dayPart.menuOrder.concat([menuId]),
      updatedAt: newMenu.updatedAt,
    }

    const dayPartRef = this.getDayPartsRef(storeId).doc(dayPartId);
    batch.update(dayPartRef, newDayPart);

    await batch.commit();

    return {
      ...newMenu,
      id: menuId,
    };
  };

  createProduct = async (storeId, product) => {
    const newProduct = {
      ...product,
      key: product.name.toLowerCase(),
      createdAt: app.firestore.FieldValue.serverTimestamp(),
      updatedAt: app.firestore.FieldValue.serverTimestamp(),
    };

    const newStore = {
      productCount: app.firestore.FieldValue.increment(1),
    };

    const batch = this.db.batch();

    const productRef = this.getProductsRef(storeId).doc();
    batch.set(productRef, newProduct);

    const storeRef = this.getStoresRef().doc(storeId);
    batch.update(storeRef, newStore);

    await batch.commit();

    return {
      id: productRef.id,
      ...newProduct,
    };
  };

  /**
   * Create a store.
   * @param {string} userId Identifier of user who creates the store. This store will be referenced in the user stores list.
   * @param {*} store Store object.
   */
  createStore = async (userId, store) => {
    const newStore = {
      ...store,
      createdAt: app.firestore.FieldValue.serverTimestamp(),
      updatedAt: app.firestore.FieldValue.serverTimestamp(),
    };

    // Daypart
    const newDayPart = this.createDefaultDayPartObj();

    const batch = this.db.batch();

    const storeRef = this.getStoresRef().doc();
    batch.set(storeRef, newStore);

    const dayPartRef = this.getDayPartsRef(storeRef.id).doc(DEFAULT_DAY_PART_ID);
    batch.set(dayPartRef, newDayPart);

    const userStoreRef = this.getUserStoresRef(userId).doc(storeRef.id);
    batch.set(userStoreRef, { name: newStore.name });

    await batch.commit();

    return {
      ...newStore,
      id: storeRef.id,
      dayParts: [
        {
          ...newDayPart,
          id: dayPartRef.id,
        }
      ],
    };
  };

  /**
   * Create user doc.
   * @param {string} userId User identifier.
   * @param {object} user User object.
   * @param {object} store Store object.
   */
  createUser = async (userId, user) => {
    // User
    const newUser = {
      ...user,
      createdAt: app.firestore.FieldValue.serverTimestamp(),
      updatedAt: app.firestore.FieldValue.serverTimestamp(),
    };

    const batch = this.db.batch();

    const userRef = this.getUsersRef().doc(userId)
    batch.set(userRef, newUser);

    await batch.commit();

    return {
      ...newUser,
      id: userRef.id,
    };
  };

  deleteInvitation = (invitationId, deleteStore) => {
    const batch = this.db.batch();

    const invitationRef = this.getInvitationsRef().doc(invitationId);
    batch.delete(invitationRef);

    if (deleteStore) {
      const storeRef = this.getStoresRef().doc(invitationId);
      batch.delete(storeRef);
    }

    return batch.commit();
  };

  deleteProduct = (storeId, productId) => {
    const batch = this.db.batch();

    const newStore = {
      productCount: app.firestore.FieldValue.increment(-1),
    };

    const storeRef = this.getStoresRef().doc(storeId);
    batch.update(storeRef, newStore);

    const productRef = this.getProductsRef(storeId).doc(productId)
    batch.delete(productRef);

    return batch.commit();
  };

  deleteStoreMenu = async (storeId, menuId) => {
    const batch = this.db.batch();

    const storeMenuRef = this.getStoreMenusRef(storeId).doc(menuId);
    batch.delete(storeMenuRef);

    // TODO: Refactor this to cloud functions with
    //       multiple day part support

    /* Start day part refactor */
    const dayPartRef = this.getDayPartsRef(storeId).doc('default');
    const dayPart = await dayPartRef
      .get()
      .then(doc => doc.data());

    // Remove from menus catalog
    delete dayPart.menus[menuId];

    // Remove from menu order
    const index = dayPart.menuOrder.indexOf(menuId);
    dayPart.menuOrder.splice(index, 1);

    batch.update(dayPartRef, dayPart);

    /* End day part refactor */

    await batch.commit();
  }

  deleteDayPartMenu = (storeId, dayPartId, menuId) => this
    .getDayPartMenusRef(storeId, dayPartId).doc(menuId)
    .delete();

  getDayPartMenu = (storeId, dayPartId, menuId) => this
    .getDayPartMenusRef(storeId, dayPartId).doc(menuId)
    .get()
    .then(doc => doc.data());

  getDayPartMenus = (storeId, dayPartId) => this
    .getDayPartMenusRef(storeId, dayPartId)
    .get()
    .then(snapshot => snapshot.docs.map(doc => Object.assign({ id: doc.id }, doc.data())));

  /**
   * @deprecated Use getStoreMenusRef()
   * @param {*} storeId 
   * @param {*} dayPartId 
   */
  getDayPartMenusRef = (storeId, dayPartId) => this
    .getDayPartsRef(storeId).doc(dayPartId)
    .collection('menus');

  getDayPartsPublic = (storeId) => this
    .getDayPartsRef(storeId)
    .where('public', '==', true)
    .get()
    .then(snapshot => snapshot.docs.map(doc => Object.assign({ id: doc.id }, doc.data())));

  getDayPart = (storeId, dayPartId) => this
    .getDayPartsRef(storeId).doc(dayPartId)
    .get()
    .then(doc => doc.data());

  getDayPartsRef = (storeId) => this
    .getStoresRef().doc(storeId)
    .collection('dayparts');

  getImageUrl = (path) => this
    .getImageRef(path)
    .getDownloadURL();

  getImageRef = (path) => this.storage.ref(path);

  getInvitation = (invitationId) => this
    .getInvitationsRef().doc(invitationId)
    .get()
    .then(doc => doc.data());

  getInvitations = () => this
    .getInvitationsRef()
    .orderBy('name')
    .get()
    .then(snapshot => snapshot.docs.map(doc => Object.assign({ id: doc.id }, doc.data())));

  getInvitationsRef = () => this.db.collection('invitations');

  getProduct = (storeId, productId) => this
    .getProductsRef(storeId).doc(productId)
    .get()
    .then(doc => doc.data());

  getProductByName = (storeId, name) => this
    .getProductsRef(storeId)
    .where('key', '==', name.toLowerCase())
    .limit(1)
    .get()
    .then(snapshot => {
      if (!snapshot.docs.length) {
        return null;
      }

      const doc = snapshot.docs[0];

      return {
        id: doc.id,
        ...doc.data(),
      };
    });

  getProducts = (storeId) => this
    .getProductsRef(storeId)
    .orderBy('name')
    .get()
    .then(snapshot => snapshot.docs.map(doc => Object.assign({ id: doc.id, }, doc.data())));

  getProductsRef = (storeId) => this
    .getStoresRef().doc(storeId)
    .collection('products');

  getSettingsRef = (userId) => this
    .getUsersRef().doc(userId)
    .collection('settings');

  getStore = (storeId) => this
    .getStoresRef().doc(storeId)
    .get()
    .then(doc => doc.data());

  getStoreMenu = (storeId, menuId) => this
    .getStoreMenusRef(storeId).doc(menuId)
    .get()
    .then(doc => doc.data());

  getStoreMenusRef = (storeId) => this.db
    .collection('stores').doc(storeId)
    .collection('menus');

  getStoresRef = () => this.db.collection('stores');

  getUser = (id) => this
    .getUsersRef().doc(id)
    .get()
    .then(doc => doc.data());

  getUserClaims = async () => {
    if (!this.auth.currentUser) {
      return {};
    }

    const { claims } = await this.auth.currentUser.getIdTokenResult();
    return claims;
  }

  getUserRoles = (userId) => this
    .getUserRolesRef(userId)
    .get()
    .then(snapshot => snapshot.docs.map(doc => Object.assign({ id: doc.id }, doc.data())));

  getUserRolesRef = (userId) => this
    .getUsersRef().doc(userId)
    .collection('roles');

  getUsersRef = () => this.db.collection('users');

  getUserStoresRef = (userId) => this
    .getUsersRef().doc(userId)
    .collection('user_stores');

  logEvent = (eventName, eventParams) => this.analytics.logEvent(eventName, eventParams);

  logError = (error, errorParams) => this
    .analytics
    .logEvent('exception', {
      ...errorParams,
      description: error.message,
    });

  refreshToken = () => this.auth.currentUser.getIdToken(true);

  sendPasswordResetEmail = (email) => {
    const doSendPasswordResetEmail = this.functions.httpsCallable('sendPasswordResetEmail');
    return doSendPasswordResetEmail({ email });
  };

  sendEmailVerification = () => {
    const sendEmail = this.functions.httpsCallable('sendEmailVerification');
    return sendEmail({ email: this.auth.currentUser?.email });
  };

  setClaims = async (claims) => {
    const { store, storeId, version } = await this.getUserClaims();

    const newClaims = {
      store,
      storeId,
      version,
      ...claims,
    };

    const setCustomUserClaims = this.functions.httpsCallable('setCustomUserClaims');
    await setCustomUserClaims(newClaims);
  };

  signIn = () => {
    const provider = new app.auth.GoogleAuthProvider();
    provider.addScope('profile');
    provider.addScope('email');
    provider.addScope('openid');
    return this.auth.signInWithRedirect(provider);
  };

  signInWithEmailAndPassword = (email, password) => this.auth.signInWithEmailAndPassword(email, password);

  signUpWithEmailAndPassword = (email, password) => this.auth.createUserWithEmailAndPassword(email, password);

  signOut = () => this.auth.signOut();

  tryMigrate = () => {
    const tryMigrate = this.functions.httpsCallable('tryMigrate');
    return tryMigrate();
  }

  updateStore = (storeId, store) => {
    const newStore = {
      ...store,
      updated: app.firestore.FieldValue.serverTimestamp(),
    };

    // Don't update in case this value was parsed, it will no longer by timestamp
    delete newStore.createdAt;

    return this
      .getStoresRef().doc(storeId)
      .update(newStore);
  }

  updateDayPart = (storeId, dayPartId, dayPart) => {
    const newDayPart = {
      ...dayPart,
      updatedAt: app.firestore.FieldValue.serverTimestamp(),
    };

    // Don't update in case this value was parsed, it will no longer by timestamp
    delete newDayPart.createdAt;

    return this
      .getDayPartsRef(storeId).doc(dayPartId)
      .update(newDayPart);
  };

  updateDayPartMenu = async (storeId, dayPartId, menuId, menu) => {
    const newMenu = {
      ...menu,
      updatedAt: app.firestore.FieldValue.serverTimestamp(),
    };

    const newDayPart = {
      [`menus.${menuId}`]: {
        name: newMenu.name,
        active: newMenu.active,
        updatedAt: newMenu.updatedAt,
      },
    };

    // Don't update in case this value was parsed, it will no longer by timestamp
    delete newMenu.createdAt;

    const batch = this.db.batch();

    const storeMenuRef = this.getStoreMenusRef(storeId).doc(menuId);
    batch.update(storeMenuRef, newMenu);

    const dayPartRef = this.getDayPartsRef(dayPartId);
    batch.update(dayPartRef, newDayPart);

    await batch.commit();
  };

  updateProduct = (storeId, productId, product) => {
    const newProduct = {
      ...product,
      updatedAt: app.firestore.FieldValue.serverTimestamp(),
    };

    // Don't update in case this value was parsed, it will no longer by timestamp
    delete newProduct.createdAt;

    return this
      .getProductsRef(storeId).doc(productId)
      .update(newProduct);
  };

  updateStoreMenu = (storeId, menuId, dayPartId, menu) => {
    const newMenu = {
      ...menu,
      updatedAt: app.firestore.FieldValue.serverTimestamp(),
    };

    // Don't update in case this value was parsed, it will no longer by timestamp
    delete newMenu.createdAt;

    const newDayPart = {
      [`menus.${menuId}`]: {
        name: menu.name,
        active: menu.active,
        updatedAt: newMenu.updatedAt,
      },
    };

    const batch = this.db.batch();

    const menuRef = this.getStoreMenusRef(storeId).doc(menuId);
    batch.update(menuRef, newMenu);

    const dayPartRef = this.getDayPartsRef(storeId).doc(dayPartId);
    batch.update(dayPartRef, newDayPart);

    return batch.commit();
  };

  /**
   * Verifies email with `oobCode` and reloads cached user data
   * to reflect verification if user is signed in. Requires a 
   * "reload" on the client side.
   * @param {string} code Verification `oobCode` from link.
   */
  verifyEmail = async (code) => {
    await this.auth.applyActionCode(code);

    if (this.auth.currentUser) {
      await this.auth.currentUser.reload();
    }
  };

  verifyPasswordResetCode = (code) => this.auth.verifyPasswordResetCode(code);

  /**
   * Helpers
   */

  createDefaultDayPartObj = () => ({
    name: 'Default',
    startTime: '00:00:00',
    endTime: '00:00:00',
    public: true,
    menus: {},
    menuOrder: [],
    createdAt: app.firestore.FieldValue.serverTimestamp(),
    updatedAt: app.firestore.FieldValue.serverTimestamp(),
  });
}