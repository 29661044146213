import React from 'react';
import PropTypes from 'prop-types';
import Numeral from 'numeral';
import { Button, Carousel, Form, Modal, Spinner } from 'react-bootstrap';
import { AsyncTypeahead, Menu, MenuItem } from 'react-bootstrap-typeahead';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { getSlug } from './reducers/helpers';

import ProductCreate from './ProductCreate';
import './ProductAdd.scss';

const ProductAdd = ({
  catId,
  productList,
  productKeys,
  show,
  working,
  onAddNewProduct,
  onConfirm,
  onCancel,
}) => {
  const [options, setOptions] = React.useState([]);
  const [selected, setSelected] = React.useState();
  const [slideIndex, setSlideIndex] = React.useState(0);
  const [focusIndex, setFocusIndex] = React.useState(0);
  const searchRef = React.useRef();
  const btnAddRef = React.useRef();

  const handleAddNewProduct = (values) => {
    onAddNewProduct(catId, values);
  };

  const handleChangeSelected = (options) => {
    setSelected(options[0]);

    // Doesn't focus when state is changing on render. 
    // Just naively wait and assume it's done.
    setTimeout(() => btnAddRef.current.focus(), 500);
  };

  const handleProductAdd = () => {
    const product = { ...selected };
    const id = product.id;

    delete product.id;

    onConfirm(id, catId, product);
  };

  const handleSearch = (query) => {
    const filteredProducts = productList.filter(prod => prod.name.toLowerCase().indexOf(query.toLowerCase()) >= 0);
    setOptions(filteredProducts);
  };

  React.useEffect(() => {
    if (focusIndex === 0) {
      searchRef.current.focus();
    }
  }, [focusIndex]);

  React.useEffect(() => {
    if (!selected) {
      searchRef.current.focus()
    }
  }, [selected]);

  return (
    <Modal show={show} onHide={() => onCancel()} className="mm-success-top">
      <Carousel
        activeIndex={slideIndex}
        controls={false}
        indicators={false}
        onSlid={() => setFocusIndex(slideIndex)}
      >
        <Carousel.Item className="search px-1">
          <>
            <Modal.Body className="search">

              {!selected &&
                <Form.Group>
                  <Form.Label className="d-flex justify-content-between">
                    Name

                    <Button
                      size="sm"
                      variant="link text-decoration-none"
                      onClick={() => setSlideIndex(1)}
                    >
                      <FontAwesomeIcon icon={faPlus} />
                    </Button>
                  </Form.Label>

                  <AsyncTypeahead
                    id="productSearch"
                    isLoading={false}
                    ref={searchRef}
                    labelKey={option => option.name}
                    minLength={1}
                    maxHeight="350px"
                    onSearch={handleSearch}
                    options={options}
                    placeholder="Search for product..."
                    onChange={handleChangeSelected}
                    renderMenu={(results, menuProps) =>
                      <Menu className="search__menu" {...menuProps}>
                        {results.map((prod, index) => {
                          const name = getSlug(prod.name);
                          const isOnMenu = !!productKeys[name];

                          return (
                            <MenuItem className="search__item" key={index} option={prod} position={index} disabled={isOnMenu}>
                              <h6 className={`search__name ${isOnMenu ? 'text-muted' : ''}`}>
                                {prod.name} {isOnMenu && '(added)'}
                              </h6>
                              <div className="search__desc">{prod.description}</div>
                              <div className={`search__price ${isOnMenu ? 'text-muted' : ''}`}>{Numeral(prod.price).format('$0,0.00')}</div>
                            </MenuItem>
                          );
                        })}
                      </Menu>
                    }
                  />
                </Form.Group>
              }

              {selected &&
                <>
                  <div className="d-flex justify-content-between align-items-center">
                    <h3>{selected.name}</h3>
                    <Button
                      variant="link"
                      size="sm"
                      onClick={() => setSelected(null)}
                    >
                      Change
                    </Button>
                  </div>

                  <p>{selected.description}</p>
                  <h6>{Numeral(selected.price).format('$0,0.00')}</h6>
                </>
              }

            </Modal.Body>

            <Modal.Footer>
              <Button
                variant="light"
                onClick={() => onCancel()}
              >
                Cancel
              </Button>

              <Button
                className="d-flex align-items-center"
                disabled={!selected?.id || working}
                ref={btnAddRef}
                onClick={handleProductAdd}
              >
                Add {working && <Spinner animation="border" size="sm" className="ml-2" />}
              </Button>
            </Modal.Footer>
          </>

          </Carousel.Item>

          <Carousel.Item className="search px-1">
            <ProductCreate
              isActive={focusIndex === 1}
              productList={productList}
              working={working}
              onBack={() => setSlideIndex(0)}
              onCancel={() => onCancel()}
              onConfirm={handleAddNewProduct}
            />
          </Carousel.Item>

        </Carousel>

    </Modal>
  );
};

ProductAdd.propTypes = {
  catId: PropTypes.string.isRequired,
  productList: PropTypes.array.isRequired,
  productKeys: PropTypes.object.isRequired,
  show: PropTypes.bool.isRequired,
  working: PropTypes.bool.isRequired,
  onAddNewProduct: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default ProductAdd;