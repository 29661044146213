/**
 * All functions will return a new menu.
 */

export const categoryDelete = (menu, catId, index) => {
  menu.categoryOrder.splice(index, 1);
  delete menu.categories[catId];

  return menu;
};

/**
 * Add or update category. If category is not found in the categories
 * catalog of the menu, it will be added.
 * @param {object} menu New menu object.
 * @param {string} catId Category id.
 * @param {object} category Category object.
 */
export const categoryAddOrUpdate = (menu, catId, category) => {
  const categoryOrder = menu.categoryOrder.slice();

  if (!menu.categories[catId]) {
    categoryOrder.push(catId);
  }

  return {
    ...menu,
    categoryOrder,
    categories: {
      ...menu.categories,
      [catId]: {
        ...menu.categories[catId],
        ...category,
      },
    },
  }
};

export const productAddOrUpdate = (menu, catId, prodId, product) => {
  const category = menu.categories[catId];
  const productOrder = category.productOrder.slice();

  // This is a new product
  if (!menu.products[prodId]) {
    productOrder.push(prodId);
  }

  return {
    ...menu,
    products: {
      ...menu.products,
      [prodId]: {
        catId,
        name: product.name,
        description: product.description,
        price: product.price,
      },
    },
    categories: {
      ...menu.categories,
      [catId]: {
        ...category,
        productOrder,
      },
    },
  };
};

export const productDelete = (menu, catId, prodId, prodIndex) => {
  const category = menu.categories[catId];
  const products = { ...menu.products };
  const productOrder = category.productOrder.slice();

  productOrder.splice(prodIndex, 1);
  delete products[prodId];

  return {
    ...menu,
    products,
    categories: {
      ...menu.categories,
      [catId]: {
        ...category,
        productOrder,
      },
    },
  };
};