import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';

import MainNav from './MainNav';

const Content = styled.div`
  padding-top: ${props => props.showMainNav ? '4rem' : 0};

  @media (min-width: 768px) {
    margin-left: ${props => props.showMainNav ? '232px' : '0' };
    padding-top: 0;
  }
`;

const MainBody = ({
  children,
  location,
  showMainNav,
  hideMainNavPaths = [],
}) => {

  // Hide nav on certain paths
  const canShowNav = !hideMainNavPaths.find(regExPath => location.pathname.match(regExPath));

  // If nav can be shown and instructed to show, then show
  const showNav = canShowNav && showMainNav;

  return (
    <>
      {showNav && <MainNav />}

      <Content id="content" showMainNav={showNav}>
        {children}
      </Content>
    </>
  );
};

MainBody.propTypes = {
  showMainNav: PropTypes.bool.isRequired,
  hideMainNavPaths: PropTypes.array,
};

export default withRouter(MainBody);