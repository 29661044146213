import React from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { Button, Col, Form, Spinner } from 'react-bootstrap';
import { Formik, Form as FormikForm } from 'formik';
import { states } from '../common/Constants';

const AddressSchema = Yup.object().shape({
  address: Yup.string()
    .required('Address is required'),
  city: Yup.string()
    .required('City is required'),
  state: Yup.string()
    .required('State is required'),
  zip: Yup.string()
    .required('Zip code is required'),
});

const SetupAddress = ({
  address = { address: '', address2: '', city: '', state: '', zip: '' },
  working = false,
  confirmLabel = 'Save',
  autoFocus = false,
  onConfirm,
  onBack,
}) => {
  const addressRef = React.useRef();

  React.useEffect(() => {
    if (autoFocus) {
      addressRef.current.focus()
    }
  }, [autoFocus]);

  return (
    <Formik
      enableReinitialize={true}
      initialValues={address}
      validationSchema={AddressSchema}
      onSubmit={values => onConfirm(values)}
    >

      {({ errors, handleChange, isValid, values }) =>
        <FormikForm>

          <Form.Row>
            <Col lg={9}>
              <Form.Group>
                <Form.Label>Address</Form.Label>
                <Form.Control
                  name="address"
                  ref={addressRef}
                  isInvalid={!!errors.address}
                  value={values.address}
                  onChange={handleChange}
                />
                <Form.Control.Feedback type="invalid">{errors.address}</Form.Control.Feedback>
              </Form.Group>
            </Col>

            <Col lg={3}>
              <Form.Group>
                <Form.Label>Ste/Unit (optional)</Form.Label>
                <Form.Control
                  name="address2"
                  isInvalid={!!errors.address2}
                  value={values.address2}
                  onChange={handleChange}
                />
                <Form.Control.Feedback type="invalid">{errors.address2}</Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Form.Row>

          <Form.Row>
            <Col md={12}>
              <Form.Group>
                <Form.Label>City</Form.Label>
                <Form.Control
                  name="city"
                  isInvalid={!!errors.city}
                  value={values.city}
                  onChange={handleChange}
                />
                <Form.Control.Feedback type="invalid">{errors.city}</Form.Control.Feedback>
              </Form.Group>
            </Col>

            <Col md={12}>
              <Form.Group>
                <Form.Label>State</Form.Label>
                <Form.Control
                  name="state"
                  as="select"
                  isInvalid={!!errors.state}
                  style={{ textTransform: 'uppercase' }}
                  value={values.state}
                  onChange={handleChange}
                >
                  {states.map(st => <option key={st} value={st}>{st}</option>)}
                </Form.Control>
                <Form.Control.Feedback type="invalid">{errors.state}</Form.Control.Feedback>
              </Form.Group>
            </Col>

            <Col md={12}>
              <Form.Group>
                <Form.Label>Zip</Form.Label>
                <Form.Control
                  name="zip"
                  isInvalid={!!errors.zip}
                  value={values.zip}
                  onChange={handleChange}
                />
                <Form.Control.Feedback type="invalid">{errors.zip}</Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Form.Row>

          <div className={`d-flex justify-content-${onBack ? 'between' : 'end'}`}>
            {onBack && <Button variant="light" onClick={() => onBack()}>Back</Button>}
            <Button
              className="d-flex align-items-center"
              type="submit"
              disabled={!isValid || working}
            >
              {confirmLabel} {working && <Spinner animation="border" size="sm" className="ml-2" />}
            </Button>
          </div>

        </FormikForm>
      }

    </Formik>
  );
};

SetupAddress.propTypes = {
  onConfirm: PropTypes.func.isRequired,
  address: PropTypes.object,
  autoFocus: PropTypes.bool,
  working: PropTypes.bool,
  onBack: PropTypes.func,
  confirmLabel: PropTypes.string,
};

export default SetupAddress;