import React from 'react';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import styled from 'styled-components';
import Loading from './Loading';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudUploadAlt, faHandPointer, faTrash } from '@fortawesome/free-solid-svg-icons';
import { withFirebase } from '../providers';

import './ImageUpload.scss';
import { Toast } from '.';

const IMG_MAX_SIZE = 1048576;
const IMG_UPLOAD_MULTIPLE = false;
const IMG_MIME_TYPES = ['image/*'];
const IMG_ERRORS = {
  'default': 'File must be JPG/PNG with 1MB max',
  'file-invalid-type': 'Unsupported file',
  'file-too-large': 'Image must be 1MB max',
};

// Make clear distinction where things are in the render func
const Container = styled.div``;
const Drop = styled.div``;
const ButtonRow = styled.div``;
const Actions = styled.div``;

const ImageUpload = ({
  imageUrl,
  working,
  onAccepted,
  onDeleted,
}) => {

  const handleRejected = (e) => {

    // Grab the first error from the first file
    const [file] = e;
    const [{ code }] = file.errors;
    const error = IMG_ERRORS[code] || IMG_ERRORS.default;

    Toast({ error });
  }

  return (
    <Container>
      <Drop className="drop">

        {!imageUrl &&
          <Dropzone
            accept={IMG_MIME_TYPES}
            maxSize={IMG_MAX_SIZE}
            multiple={IMG_UPLOAD_MULTIPLE}
            onDrop={onAccepted}
            onDropRejected={handleRejected}
          >
            {({ getRootProps, getInputProps, isDragAccept }) =>
              <div className="drop__area" {...getRootProps()}>

                <input {...getInputProps()} />

                <div className={`drop__placeholder ${isDragAccept ? 'drop__placeholder--active' : null}`}>
                  <div className="drop__desc">
                    <span>
                      <FontAwesomeIcon icon={faHandPointer} className="mr-2" /> Drag and drop image here
                    </span>
                    <span className="my-2">OR</span>

                    <span className="btn btn-outline-primary">Add Image</span>
                  </div>
                </div>
              
              </div>
            }
          </Dropzone>
        }

        {imageUrl &&
          <div className="drop__content animate__animated animate__fadeIn">
            <img className="drop__img" src={imageUrl} alt="product" />
          </div>
        }

        {working &&
          <div className="drop__loading">
            <Loading />
          </div>
        }

      </Drop>

      <Actions>
        {!imageUrl &&
          <small className="ml-2 font-weight-bold text-muted">1MB max</small>
        }

        {imageUrl &&
          <ButtonRow className="btns">

            <Button
              variant="link"
              className="text-danger text-decoration-none"
              size="sm"
              onClick={() => onDeleted()}
            >
              <FontAwesomeIcon icon={faTrash} className="mr-2" />
              <small>Remove Image</small>
            </Button>

            <div>
              <Dropzone
                accept={IMG_MIME_TYPES}
                maxSize={IMG_MAX_SIZE}
                multiple={IMG_UPLOAD_MULTIPLE}
                onDrop={onAccepted}
                onDropRejected={handleRejected}
              >
                {({ getRootProps, getInputProps }) =>
                  <div className="btn btn-link" {...getRootProps()}>
                    <input {...getInputProps()} />
                    <FontAwesomeIcon icon={faCloudUploadAlt} className="mr-2" />
                    <small>Replace Image</small>
                  </div>
                }
              </Dropzone>
            </div>

          </ButtonRow>
        }

      </Actions>
    </Container>
  );
};

ImageUpload.propTypes = {
  imageUrl: PropTypes.string,
  working: PropTypes.bool.isRequired,
  onAccepted: PropTypes.func.isRequired,
  onDeleted: PropTypes.func.isRequired,
};

export default withFirebase(ImageUpload);