import React from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { Button, Form, Modal } from 'react-bootstrap';
import { Formik, Form as FormikForm } from 'formik';

const InvitationSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
});

const InvitationCreateModal = ({
  show,
  onConfirm,
  onCancel,
}) => {
  const nameRef = React.useRef();

  React.useEffect(() => {
    if (show) {
      nameRef.current.focus();
    }
  }, [show]);

  return (
    <Modal show={show} centered={true} onHide={() => onCancel()}>
      <Modal.Body className="p-4">
        <h3>New Invitation</h3>

        <Formik
          initialValues={{ name: '', type: 'account' }}
          validationSchema={InvitationSchema}
          onSubmit={(values) => onConfirm(values)}
        >
          {({ errors, handleChange, isSubmitting, isValid, values }) =>
            <FormikForm>
              <Form.Group>
                <Form.Label>Name</Form.Label>
                <Form.Control
                  name="name"
                  ref={nameRef}
                  isInvalid={!!errors.name}
                  value={values.name}
                  onChange={handleChange}
                />
                <Form.Control.Feedback type="invalid">{errors.name}</Form.Control.Feedback>
              </Form.Group>

              <div className="text-right">
                <Button
                  className="ml-2"
                  variant="light"
                  onClick={() => onCancel()}
                >
                  Cancel
                </Button>

                <Button
                  className="ml-2"
                  type="submit"
                  disabled={isSubmitting || !isValid}
                >
                  Create
                </Button>
              </div>
            </FormikForm>
          }
        </Formik>
      </Modal.Body>
    </Modal>
  );
};

InvitationCreateModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default InvitationCreateModal;