import React from 'react';
import { Button, Container, Nav, Spinner, Alert, Badge } from 'react-bootstrap';
import { compose } from 'recompose';
import { ERROR_GENERIC } from '../common/Constants';
import { LoadingModal, Toast } from '../common/components';
import { withAuth, withFirebase } from '../common/providers';

import QrModal from './QrModal';
import './StoreEdit.scss';
import NameVerification from './StoreEditSections/NameVerification';
import PublicLinkSection from './StoreEditSections/PublicLinkSection';
import StoreLocation from './StoreEditSections/StoreLocation';
import StoreContact from './StoreEditSections/StoreContact';
import BrandSection from './StoreEditSections/BrandSection';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { faEnvelope } from '@fortawesome/free-regular-svg-icons';

const DefaultStore = {
  name: '',
  verified: false,
};

const Profile = ({
  firebase,
  user,
}) => {
  const { email, emailVerified, storeId } = user;
  const [loading, setLoading] = React.useState(true);
  const [working, setWorking] = React.useState(false);
  const [sending, setSending] = React.useState(false);
  const [qrImageUrl, setQrImageUrl] = React.useState();
  const [editSection, setEditSection] = React.useState();
  const [showQr, setShowQr] = React.useState(false);
  const [store, setStore] = React.useState(DefaultStore);
  const storeName = store?.name;

  const handleUpdateNonEssenentialStoreInfo = async (storeInfo) => {
    try {
      setWorking(true);

      await firebase.updateStore(storeId, storeInfo);

      Toast({ success: 'Saved!' });
    } catch (err) {
      Toast({ error: ERROR_GENERIC });
    }

    setWorking(false);
  };

  const handleSendEmailVerification = async () => {
    try {
      setSending(true);
      await firebase.sendEmailVerification();
      Toast({ icon: faEnvelope, success: 'Email sent!' });
    } catch (err) {
      Toast({ error: ERROR_GENERIC });
    }

    setSending(false);
  };

  const handleStoreNameUpdate = async (values) => {
    try {
      setWorking(true);

      await firebase.updateStore(storeId, values);

      if (storeName && storeName !== values.name) {
        await firebase.setClaims({ store: values.name });
        await firebase.refreshToken();

      } else {
        setWorking(false);
      }

      Toast({ success: 'Saved!' });

      setTimeout(() => window.location.reload(), 1000);
    } catch (err) {
      Toast({ error: ERROR_GENERIC });
      setWorking(false);
    }
  };

  React.useEffect(() => {
    const fetchProfile = async () => {
      try {
        const apiStore = await firebase.getStore(storeId);
        const curStore = {
          ...DefaultStore,
          ...apiStore,
        };

        const qrImage = await firebase.getImageUrl(curStore.qrImagePath);

        setQrImageUrl(qrImage);
        setStore(curStore);
      } catch (err) {
        firebase.logError(err);
        Toast({ error: ERROR_GENERIC });
      }

      setLoading(false);
    };

    fetchProfile();
  }, [firebase, email, storeId]);

  return (
    <div>

      <Container className="content-body">
        <div className="d-flex flex-row align-items-center">
          <h1>Account</h1>
          <div className="ml-2">
            <Badge pill variant="secondary">Pilot</Badge>
          </div>
        </div>

        {!emailVerified &&
          <Alert variant="warning" className="d-flex flex-row animate__animated animate__shakeX">
            <FontAwesomeIcon icon={faExclamationTriangle} className="mr-2 position-relative" style={{ top: 5 }} />
            <div className="d-flex flex-row align-items-center">
              Verify your email to complete registration.
              <Button
                className="mx-2 d-flex align-items-center"
                variant="outline-primary"
                size="sm"
                onClick={handleSendEmailVerification}
              >
                Resend
                {sending && <Spinner animation="border" size="sm" className="ml-2" />}
              </Button>
            </div>
          </Alert>
        }
      </Container>


      <Container className="content-body p-0">
        <Nav activeKey="detail" className="mm-nav px-4">
          <Nav.Item>
            <Nav.Link eventKey="detail" active>Store</Nav.Link>
          </Nav.Item>
        </Nav>
      </Container>

      <hr className="m-0" />

      {loading &&
        <div className="text-center my-4">
          <Spinner animation="border" variant="primary" />
        </div>
      }

      {!loading && (
        <>
          {working && <LoadingModal />}

          <QrModal
            qrImageUrl={qrImageUrl}
            qrUrl={store.url}
            show={showQr}
            onClose={() => setShowQr(false)}
          />

          <Container className="content-body">

            <NameVerification
              store={store}
              storeId={storeId}
              editing={editSection === 'name'}
              working={working}
              onEditing={() => setEditSection('name')}
              onConfirm={handleStoreNameUpdate}
              onCancel={() => setEditSection(null)}
            />

            <hr />
            <BrandSection
              store={store}
              editing={editSection === 'brand'}
              working={working}
              onEditing={() => setEditSection('brand')}
              onConfirm={handleUpdateNonEssenentialStoreInfo}
              onCancel={() => setEditSection(null)}
            />

            <hr />
            <PublicLinkSection url={store.url} onShowQr={() => setShowQr(true)} />

            <hr />
            <StoreLocation
              store={store}
              editing={editSection === 'location'}
              onEditing={() => setEditSection('location')}
              onConfirm={handleUpdateNonEssenentialStoreInfo}
              onCancel={() => setEditSection(null)}
            />

            <hr />
            <StoreContact
              store={store}
              editing={editSection === 'contact'}
              onEditing={() => setEditSection('contact')}
              onConfirm={handleUpdateNonEssenentialStoreInfo}
              onCancel={() => setEditSection(null)}
            />

          </Container>
        </>
      )}
    </div>
  );
};

export default compose(
  withAuth,
  withFirebase,
)(Profile);