import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Button, Modal } from 'react-bootstrap';

const QrImage = styled.img`
  width: 100%;
`;

const QrModal = ({
  qrImageUrl,
  qrUrl,
  show,
  onClose,
}) => (
  <Modal show={show} onHide={() => onClose()}>
    <Modal.Header closeButton>
    </Modal.Header>

    <Modal.Body>
      <QrImage src={qrImageUrl} alt={qrUrl} />
      <p className="mb-0 text-center">
        <a className="text-dark" href={qrUrl}>{qrUrl}</a>
      </p>
    </Modal.Body>

    <Modal.Footer>
      <Button variant="outline-primary" onClick={() => onClose()}>
        Close
      </Button>
    </Modal.Footer>
  </Modal>
);

QrModal.propTypes = {
  qrImageUrl: PropTypes.string.isRequired,
  qrUrl: PropTypes.string.isRequired,
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default QrModal;