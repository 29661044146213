import React from 'react';
import PropTypes from 'prop-types';
import { Button, Col, Collapse, Form, Row } from 'react-bootstrap';
import { BlockPicker } from 'react-color';

const BrandSection = ({
  store,
  editing,
  onEditing,
  onConfirm,
}) => {
  const [color, setColor] = React.useState(store.color || '#00a69c');

  return (
    <section className="section">
      <Row>
        <Col lg={4} className="section__title">
          <h5>Brand</h5>
        </Col>

        {!editing &&
          <>
            <Col lg={6} className="section__desc">
              Brand color
            </Col>

            <Col lg={2} className="section__edit">
              <Button variant="outline-primary" size="sm" onClick={() => onEditing()}>
                Edit
              </Button>
            </Col>
          </>
        }
      </Row>

      <Collapse in={editing}>
        <div>

          <Row>
            <Col lg={4}>
              <Form.Label>Color</Form.Label>
            </Col>
            <Col lg={8}>

              <div className="mb-2">
                <BlockPicker
                  triangle="hide"
                  color={color}
                  colors={[
                    '#00a69c',
                    '#4e4e56',
                    '#555273',
                    '#ffda69',
                    '#eb5757',
                  ]}
                  onChange={color => setColor(color.hex)}
                />
              </div>

              <Button disabled={!color} onClick={() => onConfirm({ color })}>
                Save
              </Button>

            </Col>
          </Row>

        </div>
      </Collapse>
    </section>
  );
}

BrandSection.propTypes = {
  store: PropTypes.object.isRequired,
  editing: PropTypes.bool.isRequired,
  onEditing: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};

export default BrandSection;