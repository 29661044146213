import React from 'react';
import styled from 'styled-components';
import { Button, Card, Col, Container, Form, Row, Spinner } from 'react-bootstrap';
import { Link, Switch } from 'react-router-dom';
import { compose } from 'recompose';
import { faPlus, faTimes, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { PAGE_LIMIT, ERROR_GENERIC } from '../common/Constants';
import { withAuth, withFirebase } from '../common/providers';
import { AddNewSection, ConfirmCancel, ProtectedRoute, Toast } from '../common/components';

import { ProductCreate, ProductEdit } from '../products';
import { ProductsEmpty } from '../common/images';

const EmptyImage = styled.img`
  width: 350px;
  margin-top: 3rem;
`;

const ProductList = ({
  firebase,
  history,
  location,
  match,
  user,
}) => {
  const [loading, setLoading] = React.useState(true);
  const [deleteIndex, setDeleteIndex] = React.useState(-1);
  const [products, setProducts] = React.useState([]);
  const [newProduct, setNewProduct] = React.useState();
  const [filter, setFilter] = React.useState({ page: 0, search: '' });
  const { storeId } = user;
  const SEARCH_MIN_REQUIRED = 2;

  const handleDelete = async () => {
    const { id, name } = products[deleteIndex];

    try {
      await firebase.deleteProduct(storeId, id);

      const newProducts = products.slice();
      newProducts.splice(deleteIndex, 1);

      setProducts(newProducts);
      setDeleteIndex(-1);

      Toast({ error: `${name} deleted!` });
    } catch (err) {
      firebase.logError(err);
      Toast({ error: ERROR_GENERIC });
    }
  };

  const handleSearch = (e) => {
    const { value: search } = e.target;

    setFilter({
      ...filter,
      search,
      page: 0,
    });
  };

  const visibleProducts = React.useMemo(() => {
    const { page, search } = filter;

    // Only show search results if searched
    if (search && search.length > SEARCH_MIN_REQUIRED) {
      return products.filter(prod => prod.name.toLowerCase().indexOf(search.toLowerCase()) >= 0);
    }

    return products.slice(0, (page * PAGE_LIMIT) + PAGE_LIMIT)
  }, [products, filter]);

  React.useEffect(() => {
    // Product is passed back to prod list from create/edit
    const product = location.state?.product;

    if (product) {
      setProducts(prods => {
        const newProducts = prods.slice();
        const existing = prods.find(p => p.id === product.id);

        if (!existing) {
          setNewProduct(product);
          return [product, ...newProducts];
        }

        const index = prods.indexOf(existing);
        newProducts.splice(index, 1, product);

        return newProducts;
      });

      // Reset history/location state
      history.replace(location.pathname, null);
    }
  }, [location.pathname, location.state, history])

  React.useEffect(() => {
    const fetchProducts = async () => {
      try {
        const apiProducts = await firebase.getProducts(storeId);
        setProducts(apiProducts);
      } catch (err) {
        firebase.logError(err);
      }

      setLoading(false);
    };

    fetchProducts();
  }, [firebase, storeId]);

  return (
    <>
      <Container className="content-body">

        <Row>
          <Col lg={9}>
            <h1 className="mb-4">Products</h1>
          </Col>

          <Col lg={3}>
            {!!products.length &&
            <Form.Group>
              <Form.Control
                name="search"
                placeholder="Search"
                disabled={!products.length}
                onChange={handleSearch}
              />
            </Form.Group>
            }
          </Col>
        </Row>

        {loading &&
          <div className="text-center">
            <Spinner animation="border" variant="primary" />
          </div>
        }

        {deleteIndex >= 0 &&
          <ConfirmCancel
            show={!!~deleteIndex}
            title="Delete Product"
            content={
              <div>
                <p>Permanently delete <strong>{products[deleteIndex]?.name}</strong>?</p>
                <p>This product will also be deleted from:</p>

                <ul>
                  <li>Menus</li>
                </ul>
              </div>
            }
            variant="danger"
            icon={faTrash}
            onConfirm={() => handleDelete()}
            onCancel={() => setDeleteIndex(-1)}
          />
        }

        {!loading &&
          <>

            {!!products.length &&
              <AddNewSection to={`${match.url}/create`} className="mb-3 py-4">
                <FontAwesomeIcon icon={faPlus} className="mr-2" /> New Product
              </AddNewSection>
            }

            {!products.length &&
              <div className="text-center">
                <EmptyImage className="img-fluid" src={ProductsEmpty} alt="No products" />

                <p className="my-3 text-muted">You don't have any products</p>

                <Button size="sm" as={Link} to={`${match.url}/create`}>
                  + New Product
                </Button>
              </div>
            }

            {!!filter.search && filter.search.length > SEARCH_MIN_REQUIRED &&
              <p>
                <strong>Search Results:</strong>
              </p>
            }

            {visibleProducts.map((prod, index) =>
              <Card key={prod.id} className={`mb-3 shadow-sm ${newProduct && newProduct.id === prod.id ? 'border border-success' : null}`}>
                <Card.Body className="d-flex justify-content-between">
                  <div className="d-flex align-items-center" style={{ minWidth: 0 }}>
                    <Link className="d-block text-truncate text-dark" to={`${match.url}/edit/${prod.id}`}>
                      {prod.name}
                    </Link>
                  </div>

                  <Button variant="default" size="sm" onClick={() => setDeleteIndex(index)}>
                    <FontAwesomeIcon icon={faTimes} />
                  </Button>
                </Card.Body>
              </Card>
            )}

            {visibleProducts.length < products.length && !filter.search &&
              <p className="text-center">
                <Button variant="link" onClick={() => setFilter({ ...filter, page: filter.page + 1 })}>
                  Load more
                </Button>
              </p>
            }

          </>
        }

      </Container>

      <Switch>
        <ProtectedRoute path={`${match.url}/create`} component={ProductCreate} />
        <ProtectedRoute path={`${match.url}/edit/:productId`} component={ProductEdit} />
      </Switch>

    </>
  );
};

export default compose(
  withAuth,
  withFirebase,
)(ProductList);